html.hydrated {
   .site-header__bottom.sticky {
      position: relative;
   }
}
.ktc-widget-zone {

   .site-modal {
       display:block;
       border:2px solid #444;
       padding:20px;
   }
}

.ktc-editable-area .accordion-content {
   display:block !important;
}