/* ================================================================
   Variables
   ================================================================ */

// Color Variables
$body-bg: #fff;
$color-base: #000;
$color-invert: #fff;
$color-primary: #1536BE;
$color-secondary: #08258D;
$color-primary-alt:#08258D;
$color-light-blue: #77BEF8;
$color-gray:#EAEAEA;
$color-gray-2:#505A61;
$color-gold:#FCCD6A;
$color-orange:#E9852F;
$color-orange2:#F5634A;
$color-green:#C8E07B;
$color-green2:#81b654;
$color-green3:#237566;
$color-green4:#15d6a9;
$color-teal:#4A9C9A;
$color-purple:#6C0F7A;
$color-red:#e0170f;
$color-ice: #9ee7fa;
$color-stone: #808080;
$color-granite: #d1d1d1;
$color-lilac: #b592d1;
$color-lobster: #f5634a;

// Typography
$font-primary: "Akkurat", sans-serif;
$font-secondary: Tahoma, Geneva, sans-serif;
$font-heading: $font-primary;

$fontWeight-light: 300;
$fontWeight-normal: 400;
$fontWeight-medium: 500;
$fontWeight-semibold: 600;
$fontWeight-bold: 700;

$typography-margin-bottom: 1em;


// Breakpoints
// You can convert these at http://pxtoem.com
$bp-s: 20em; // 320px
$bp-s-max: 29.938em; // 479px
$bp-sw: 30em; // 480px
$bp-sw-max: 47.938em; // 767px
$bp-m: 48em; // 768px
$bp-m-max: 59.938em; // 959px
$bp-mw: 60em; // 960px
$bp-mw-max: 71.188em; // 1139px
$bp-l: 71.25em; // 1140px
$bp-l-max: 81.188em; // 1299px
$bp-xl: 81.25em; // 1300px

// Transitions
$trans-duration: 0.18s;
$trans-ease: ease-in-out;
$trans: all $trans-duration $trans-ease;

// Grid
$container-size: 91.875rem; // 1430px
$container-size-small: 71.25em; // 1140px
$container-size-large: 117.5rem; // 1880px
$grid-gutter: 15px;
$grid-gutter-em: 0.938em; // Changing grid gutter to em causes a compiling error :/
