/* ================================================================
Base styles
================================================================ */

.site-container {
    overflow-x: clip;
}

.section-container {
    padding:30px 0;

    &--padding-top {
        padding:30px 0 0;
    }
    &--padding-bottom {
        padding:0 0 30px;
    }

    &--white {
        background-color: #ffffff;
    }
    &--gray {
        background-color:$color-gray;
    }

    &--green {
        background-color:$color-green;
    }

    &--gradient {
        background: linear-gradient(180deg, #FFFFFF 0%, #EAEAEA 69.39%);
    }

    &--padding-small {
        padding: 15px 0;

        &.section-container--padding-top {
            padding:15px 0 0;
        }
        &.section-container--padding-bottom {
            padding:0 0 15px;
        }
    }

    &--padding-medium {
        padding: 30px 0;

        &.section-container--padding-top {
            padding:30px 0 0;
        }
        &.section-container--padding-bottom {
            padding:0 0 30px;
        }
    }

    &--padding-large {
        padding: 50px 0;

        &.section-container--padding-top {
            padding:50px 0 0;
        }
        &.section-container--padding-bottom {
            padding:0 0 50px;
        }
    }

    &--no-padding {
        padding: 0;
    }
}

@media (min-width: $bp-mw) {
    .section-container {
        padding:50px 0;

        &--padding-top {
            padding:50px 0 0;
        }
        &--padding-bottom {
            padding:0 0 50px;
        }
    
        &--padding-small {
            padding: 20px 0;

            &.section-container--padding-top {
                padding:20px 0 0;
            }
            &.section-container--padding-bottom {
                padding:0 0 20px;
            }
        }
    
        &--padding-medium {
            padding: 50px 0;

            &.section-container--padding-top {
                padding:50px 0 0;
            }
            &.section-container--padding-bottom {
                padding:0 0 50px;
            }
        }
    
        &--padding-large {
            padding: 100px 0;

            &.section-container--padding-top {
                padding:100px 0 0;
            }
            &.section-container--padding-bottom {
                padding:0 0 100px;
            }
        }
    
        &--no-padding {
            padding: 0;
        }
    }
}