.site-footer {
    background: $color-primary-alt;
    color: $color-invert;
}

.footer-top {
    border-bottom:1px solid #7387D8;
    padding:0 0 50px
}

.footer-links {
    @include resetList;
    margin:0 -15px 45px;
    @include x-rem(font-size, 16px);
}
.footer-links__block {
    border-bottom:1px solid #7387D8;
}
.footer-links__header {
    @include x-rem(font-size, 24px);
    font-weight:400;
    margin:0 0 15px;
    display:none;
}
.footer-links-expander {
    @include x-rem(font-size, 20px);
    margin:0;

    button {
        border:0;
        padding:17px 40px 17px 20px;
        background:transparent;
        position:relative;
        @include x-rem(font-size, 20px);
        font-weight:400;
        font-family: $font-primary;
        color:#fff;
        width:100%;
        text-align: left;
        cursor:pointer;

        &:before {
            content:'';
            position: absolute;
            right:26px;
            width:10px;
            height:2px;
            background:#fff;
            transform: rotate(45deg);
            top:50%;
        }
        &:after {
            content:'';
            position: absolute;
            right:20px;
            width:10px;
            height:2px;
            background:#fff;
            transform: rotate(-45deg);
            top:50%;
        }
    }
}
.footer-links__inner {
    @include resetList;
    background:#0D1C59;
    padding:20px;
}
.footer-links__item {
    margin:6px 0px;
}
.footer-links__link {
    color:$color-invert;
    font-weight:400;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}


.footer-contact {
    padding:0 10px;
}
.footer-contact__logo {
    margin:0 0 18px;
}
.footer-contact__number {
    color:#ffffff;
    display:inline-block;
    margin:0 0 18px;
    display:flex;
    align-items: center;
    text-decoration: underline;

    svg {
        fill:#A1AFE5;
        width:22px;
        height:22px;
        margin:0 8px 0 0;
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }
}
.footer-contact__route {
    font-weight:400;
    margin:0 0 18px;
}

.social-list { 
    @include resetList;
    display:flex;
    margin:0 -6px;
}
.social-list__item {
    padding:0 6px;
}
.social-list__link {
    display: block;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    border: 1px solid #A1AFE5;
    border-radius: 50%;
    position: relative;
    color:#A1AFE5;

    &:hover,
    &:focus {
        background:$color-invert;
        color:$color-primary;
        border:1px solid #ffffff;
    }
}
.social-list__icon { 
    fill: currentColor;
    width: 15px;
    height: 15px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.footer-bottom {
    padding:45px 0;
}

.footer-bottom__logos {
    display:flex;
    align-items: center;
    justify-content: center;
    margin:0 0 45px;
    
    img {
        margin:0 25px;
    }
}
.footer-bottom__nmls {
    font-weight:400;
    @include x-rem(font-size, 18px);
    margin:0 0 45px;
    text-align: center;
}

.footer-bottom__links {
    @include resetList;
    display:flex;
    justify-content: center;
    margin:0 0 20px;
    gap:60px;
    @include x-rem(font-size, 16px);
}
.footer-bottom__links-item {
}
.footer-bottom__link {
    color:#ffffff;
    text-decoration: none;
    font-weight:700;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

.footer-copyright {
    font-weight:400;
    text-align: center;
    @include x-rem(font-size, 14px);
}

@media (min-width: 600px) {
    .footer-top {
        border-bottom:1px solid #7387D8;
        padding:60px 0 50px
    }
    
    .footer-links {
        flex:1 0 auto;
        padding:0 10px;
        display:flex;
        flex-wrap: wrap;
        margin:0 -10px 25px;
    }
    .footer-links__block {
        width:50%;
        padding:0 10px;
        border:0;
        margin:0 0 20px;
    }
    .footer-links__header {
        display:block;
    }
    .footer-links-expander {
        display:none;
    }
    .footer-links__inner {
        display:block !important;
        padding:0;
        background:transparent;
    }
    .footer-links__item {
        margin:0 0 10px;
    }
}
@media (min-width: $bp-mw) {

    .footer-top {
        border-bottom:1px solid #7387D8;
        padding:60px 0 50px
    }

    .footer-top__inner {
        display:flex;
        justify-content: space-between;
        margin:0 -15px;
    }
    
    .footer-links {
        @include resetList;
        padding:0 15px;
        flex:auto;
    }
    .footer-links__item {
        margin:0 0 10px;
    }
    .footer-links__header {
        @include x-rem(font-size, 24px);
        font-weight:400;
        margin:0 0 15px;
    }
    .footer-links__link {
        color:$color-invert;
        font-weight:400;
        text-decoration: none;
    
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}
@media (min-width: 1044px) {
    .footer-bottom__inner {
        display:flex;
        justify-content: space-between;
        align-items: center;
    }
    .footer-bottom__left {
        display:flex;
        align-items: center;
    }
    .footer-bottom__logos {
        margin:0;

        img {
            margin:0 35px 0 0;
        }
    }
    .footer-bottom__nmls {
        margin:0;
    }
    
    .footer-bottom__right {
        display:flex;
        align-items: center;
    }   
    .footer-bottom__links {
        margin:0;
        gap:0;
    }
    .footer-bottom__links-item {
        margin:0 60px 0 0;
    }
}
@media (min-width: $bp-l) {

    .footer-links {
        flex-wrap: nowrap;
        margin:0 -15px;
    }
    .footer-links__block {
        width:25%;
        padding:0 15px;
        flex:1 0 auto;
    }
    .footer-contact {
        padding:0 15px;
    }
}