/* ******************************************************
   Main Import File
        - Variables / Utilities / Globals
        - Components (Navigation/Tools/Modules)
        - Vendor Imports (Third party plugins)
        - Overwrite Imports (Print/admin)
   ****************************************************** */


/* ================================================================
   Variables / Utilities / Globals
   ================================================================ */

// Variables
@import "variables";

// SASS Functions and Modules
@import "utilities";

// Normalize
@import "globals/normalize";

// Base Styles
@import "globals/boilerplate";
@import "globals/grid";

// Global Element Styles
@import "globals/typography";
@import "globals/buttons";
@import "globals/data-expander";
@import "globals/tables";
@import "globals/forms";

// Layout Styles
@import "globals/layout/base";
@import "globals/layout/header";
@import "globals/layout/header-sticky";
@import "globals/layout/interior";
@import "globals/layout/footer";



// Special
@import "globals/animations";

/* ================================================================
   Component Imports - Comment/uncomment what you don't need/need.
   ================================================================ */

// Navigation
@import "components/navigation/main-navigation";
@import "components/navigation/rail-navigation";
@import "components/navigation/hamburger";
@import "components/navigation/breadcrumbs";

// Tools
@import "components/tools/pagination";
@import "components/tools/pagetools";
@import "components/tools/search";

// Modules
@import "components/modules/banner";
@import "components/modules/events";
@import "components/modules/news";
@import "components/modules/videos";
@import "components/modules/alerts";
@import "components/modules/cookie-consent";
@import "components/modules/lists";
@import "components/modules/filters";
@import "components/modules/hero";
@import "components/modules/page-banner";
@import "components/modules/cards";
@import "components/modules/side-by-side";
@import "components/modules/image-slider";
@import "components/modules/callouts";
@import "components/modules/awards";
@import "components/modules/locations";
@import "components/modules/accordions";
@import "components/modules/tabs";
@import "components/modules/rates";
@import "components/modules/testimonials";
@import "components/modules/compare";
@import "components/modules/bio";
@import "components/modules/product";
@import "components/modules/share";


@import "components/modules/admin";

/* ================================================================
   Vendor Imports
   ================================================================ */

@import "vendor/slick";
@import "vendor/modaal";
@import "vendor/froala";


/* ================================================================
   Pages
   ================================================================ */

// Admin styles, resets and fixes
@import "pages/styleguide";


/* ================================================================
   Overwrite Imports
   ================================================================ */

@import "globals/admin-overrides";

// Print
@import "globals/print";