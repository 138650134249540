.breadcrumbs {
    @include resetList;
    @include x-rem(font-size, 12px);
    margin:0 0 20px;
    line-height:2;
}

.breadcrumbs__item {
    position:relative;
    display: inline-block;
    padding-right: 20px;

    &::after {
        content: ">";
        display: block;
        position: absolute;
        right: 7px;
        top: 50%;
        transform: translateY(-50%);
        color:#ffffff;
    }

    &:last-child {
        padding: 0;

        &::after { content: none; }
    }
}

.breadcrumbs__link {
    display: inline-block;
    color:#ffffff;
    font-weight:700;
}

.breadcrumbs__active {
    color:#ffffff;
    font-weight:400;
}

@media (min-width: $bp-mw) {
  
}