/* ================================================================
   Button Styles
   ================================================================ */

.button,
.btn {
	display:inline-block;
	padding:15px 30px;
	border:0;
	font-family:$font-primary;
	color:$color-invert;
	text-decoration:none; text-align:center;
	background:$color-primary;
	cursor: pointer;
    transition: $trans;
    line-height:1.3;
	border-radius: 26px;
	font-weight:700;

	&:hover, &:focus {
		text-decoration:none;
		background:$color-primary-alt;
	}
}
.button-2 {
	display:inline-block;
	padding:13px 26px;
	border:2px solid $color-primary;
	font-family:$font-primary;
	color:$color-invert;
	text-decoration:none; text-align:center;
	background:#ffffff;
	cursor: pointer;
    transition: $trans;
    line-height:1.3;
	border-radius: 26px;
	font-weight:700;
	color:$color-primary;

	&:hover, &:focus {
		text-decoration:none;
		background:$color-primary;
		color:#ffffff;
	}
}
.button-ghost {
	display:inline-block;
	padding:13px 26px;
	border:2px solid $color-primary-alt;
	font-family:$font-primary;
	color:$color-primary-alt;
	text-decoration:none; text-align:center;
	background:transparent;
	cursor: pointer;
    transition: $trans;
    line-height:1.3;
	border-radius: 26px;
	font-weight:700;
	color:$color-primary;

	&:hover, &:focus {
		text-decoration:none;
		background:$color-primary-alt;
		color:#ffffff;
	}
}

.back {
	@extend .button-small !optional;
	text-transform: capitalize;
}

.more {
	@extend .button-small !optional;
	text-transform:capitalize;
}

%carousel-btn {
    @include resetBtn;
	position: absolute;
    width: 60px;
    height: 60px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    font-size: 0;
    color: $color-primary;
    transition: $trans;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;

    svg {
        display: inline-block;
        width: 100%;
        height: 100%;
        fill: currentColor;
        transition: $trans;
    }

    &:hover,
    &:focus {
        color: $color-secondary;
	}

	@media (min-width: $bp-mw) {
		width: 60px;
		height: 60px;
	}
}