
.share-this-container {
    display:flex;
    align-items: center;
    margin:60px 0 0 0;
}
.share-this-heading {
    font-weight:700;
    @include x-rem(font-size, 18px);
    margin:0 20px 0 0;
}
.share-this {
    display:flex;
    list-style: none;
    padding:0;
    margin:0;
}
.share-this__item {
    padding:0 10px 0 0;
}
.share-this__link {
    display: block;
    width:30px;
    height:30px;
    position: relative;
    background:#ffffff;
    border:1px solid $color-primary-alt;
    color:$color-primary-alt;
    border-radius: 50%;

    &:hover,
    &:focus {
        background:$color-primary-alt;
        color:#fff;
    }
}
.share-this__icon {
    width:18px;
    height:18px;
    fill:currentColor;
    position: absolute;
    top:50%;
    left:50%;
    transform: translateX(-50%) translateY(-50%);
}