.awards-listing {
    
}
.awards-listing__item {
    max-width: 336px;
    text-align: center;
    margin:0 auto 30px;
}
.awards-listing__image {
    margin:0 0 20px;
}
.awards-listing__description {
    @include x-rem(font-size, 13px);
    font-weight: 700;
    text-align: center;
}

@media (min-width: $bp-m) {
    .awards-listing {
        display: flex;
        justify-content: center;
        gap:120px;
    }
    .awards-listing__item {
        margin:0;
    }
    .awards-listing__image {
        height: 100%;
        max-height: 316px;
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .awards-listing__description {
        @include x-rem(font-size, 14px);
    }
}