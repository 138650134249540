
.accordion-listing {
    padding:0;
    margin:0;
    list-style: none;
}
.accordion-listing__item {
    margin:0 0 20px;
}
.accordion-button {
	border:0;
    padding:20px;
    background:#ffffff;
    color:#000;
    position: relative;
    @include x-rem(font-size, 20px);
    font-weight:400;
    font-family: $font-primary;
    text-align: left;
    width:100%;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);

    &:before {
        content:'';
        position: absolute;
        right:20px;
        top:50%;
        width:36px;
        height:36px;
        background:$color-gold;
        border-radius: 50%;
        transform: translateY(-50%);
    }

    > span {
        position: relative;
        display:block;
        padding:0 40px 0 0;

        &:before {
            content:'';
            position: absolute;
            top:50%;
            width:15px;
            height:3px;
            right:15px;
            background:$color-primary-alt;
            transform: rotate(45deg);
        }
        &:after {
            content:'';
            position: absolute;
            top:50%;
            width:15px;
            height:3px;
            right:6px;
            background:$color-primary-alt;
            transform: rotate(-45deg);
        }
    }

    &.accordion-button--section {
        background:$color-primary-alt;
        color:$color-invert;
    }

    &.active {
        background:$color-primary-alt;
        color:$color-invert;
        box-shadow: none;

        >span {
            &:before {
                transform:rotate(-45deg);
                top:calc(50% - 2px);
            }
            &:after {
                transform:rotate(45deg);
                top:calc(50% - 2px);
            }
        }
    }
}

.accordion-content {
	padding: 20px;

	p {
		&:last-child {
			margin:0;
		}
	}

    .accordion-content__description {
        @include x-rem(font-size, 16px);
        line-height:1.5;
        margin:0 0 20px;
    }

    .accordion-listing__item {
        border-top:1px solid #cccccc;
        border-bottom:1px solid #cccccc;
    }
    .accordion-button {
        border:0;
        padding:20px;
        background:transparent;
        color:#000;
        position: relative;
        @include x-rem(font-size, 16px);
        font-weight:400;
        font-family: $font-primary;
        text-align: left;
        width:100%;
        cursor: pointer;
        border-radius: 10px;
        box-shadow: none;
    
        &:before {
            display:none;
        }
    
        > span {
            position: relative;
            display:block;
            padding:0 40px 0 0;
    
            &:before {
                content:'';
                position: absolute;
                top:50%;
                width:15px;
                height:3px;
                right:15px;
                background:$color-primary-alt;
                transform: rotate(45deg);
            }
            &:after {
                content:'';
                position: absolute;
                top:50%;
                width:15px;
                height:3px;
                right:6px;
                background:$color-primary-alt;
                transform: rotate(-45deg);
            }
        }
        &.active {
            >span {
                &:before {
                    transform:rotate(-45deg);
                }
                &:after {
                    transform:rotate(45deg);
                }
            }
        }
    }
    .accordion-content {
        padding:0 20px 20px;
        @include x-rem(font-size, 13px);
        line-height:1.5;

        p {
            @include x-rem(font-size, 13px);
            line-height:1.5;
        }

        *:last-child {
            margin:0;
        }
    }
}

.disclosures-accordion {
    background: $color-gray-2;
    .disclosures-accordion__inner {
        max-width:$container-size;
        margin: 0 auto;
    }
    .accordion-button {
        border-radius: 0;
        background: $color-gray-2;
        color:#fff;
        box-shadow: none;
        display: inline-flex;
        align-items: center;
        padding:15px 20px;

        &:before {
            display:none;
        }

        > span {
            width:36px;
            height:36px;
            background:$color-gold;
            border-radius: 50%;
            padding:0;
            margin:0 0 0 10px;
            flex-shrink: 0;
        }
    }
    .accordion-content {
        background:#fff;
    }
}

@media (min-width: $bp-mw) {
    
    .accordion-content {

        .accordion-content__description {
            @include x-rem(font-size, 18px);
        }
    
        .accordion-button {
            @include x-rem(font-size, 18px);
        }
        .accordion-content {
            @include x-rem(font-size, 13px);
        }
    }
}

/* secondary accordion */

.accordion-listing-alt {
    list-style: none;
    padding:0;
    margin:0;
}
.accordion-listing-alt__item {
    border-bottom:1px solid #ccc;

    &:first-child {
        border-top:1px solid #ccc;
    }
}
.accordion-button-alt {
    border:0;
    background:transparent;
    padding:15px 25px 15px 0;
    font-family: $font-primary;
    font-weight:400;
    position: relative;
    width:100%;
    text-align: left;
    @include x-rem(font-size, 20px);
    cursor: pointer;

    &:before {
        content:'';
        width:16px;
        height:2px;
        background:$color-primary-alt;
        position: absolute;
        right:0;
        top:50%;
        transform: translateY(-50%);
    }
    &:after {
        content:'';
        width:2px;
        height:16px;
        background:$color-primary-alt;
        position: absolute;
        right:7px;
        top:50%;
        transform: translateY(-50%);
    }

    &.active {
        &:after {
            display:none;
        }
    }

    &:hover,
    &:focus {
        color:$color-primary
    }
}
.accordion-content-alt {
    background: #EAEAEA;
    padding:20px;
    display:none;

    &.active {
        display:block;
    }

    ul {
        font-weight:400;
        list-style: disc;
        padding:0 0 0 15px;
    }
}
