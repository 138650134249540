/*================================================================
            MAIN NAVIGATION STYLES
================================================================*/


.main-navigation {
    position: relative;

    //Nav Defaults

    ul {
        list-style: none;
        padding:0;
        margin:0;
    }
    li {

    }
    a,
    button {
        display:block;
        width:100%;
        border:0;
        padding:10px;
        color:#000000;
        text-align: left;
        background:none;
        cursor:pointer;
        text-decoration: none;
        font-family: $font-primary;

        &:hover,
        &:focus {
            text-decoration: underline;
        }

        &[data-expander-toggle] {
            position: relative;
        }
    }

    .main-navigation__heading {
        font-weight:bold;
    }

    [data-expander-target].target-show {
        animation-name: toggleTargetShowSide;
        animation-fill-mode: none;
    }

    [data-expander-target].target-hide {
        animation-name: toggleTargetHideSide;
        animation-fill-mode: none;
    }

    // 1st Level
    // ********************************************* //
    > ul,
    .main-navigation-mega > ul {


        > li {

            > a,
            > button {
                font-weight:700;
                @include x-rem(font-size, 18px);
                padding:15px 20px;
                line-height: 1.4;
                

                &[data-expander-toggle] {
                    &:before {
                        content:'';
                        position: absolute;
                        right:26px;
                        width:8px;
                        height:1px;
                        background:#000;
                        transform: rotate(45deg);
                        top:50%;
                    }
                    &:after {
                        content:'';
                        position: absolute;
                        right:20px;
                        width:8px;
                        height:1px;
                        background:#000;
                        transform: rotate(-45deg);
                        top:50%;
                    }
                }

                &:hover,
                &:focus {
                    color:$color-primary;
                    text-decoration: none;
                }
                &.active {
                    &:before {
                        background:$color-primary;
                        transform: rotate(-45deg);
                    }
                    &:after {
                        background:$color-primary;
                        transform: rotate(45deg);
                    }
                }
            }

            // 2nd Level
            // ********************************************* //
            > ul {
                position: relative;
                padding:12px 0;

                &:before {
                    content:'';
                    position: absolute;
                    top:0;
                    left:0;
                    width:100%;
                    box-shadow: inset 0px 4px 7px rgba(0, 0, 0, 0.09);
                    height:12px;
                }
                &:after {
                    content:'';
                    position: absolute;
                    bottom:0;
                    left:0;
                    width:100%;
                    box-shadow:inset 0px -4px 7px rgba(0, 0, 0, 0.09);
                    height:12px;
                }

                > li {

                    > a,
                    > button {

                        font-weight:400;
                        padding:12px 20px;
                        position: relative;

                        &:hover,
                        &:focus {
                            
                        }
                    }
        
                    // 3rd Level
                    // ********************************************* //
                    
                    > ul {
                        background:#555555;
    
                        > li {
    
                            > a,
                            > button {
    
                                &:hover,
                                &:focus {
                                    text-decoration: none;
                                    background:$color-secondary;
                                }
                            }       
                            
                            // 4th Level
                            // ********************************************* //
                            
                            > ul {
                                background:#333333;
            
                                > li {
            
                                    > a,
                                    > button {
            
                                        &:hover,
                                        &:focus {
                                            text-decoration: none;
                                            background:$color-secondary;
                                        }
                                    }                                
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .main-navigation-mega__inner > ul {
        position: relative;
        padding:12px 0;

        &:before {
            content:'';
            position: absolute;
            top:0;
            left:0;
            width:100%;
            box-shadow: inset 0px 4px 7px rgba(0, 0, 0, 0.09);
            height:12px;
        }
        &:after {
            content:'';
            position: absolute;
            bottom:0;
            left:0;
            width:100%;
            box-shadow:inset 0px -4px 7px rgba(0, 0, 0, 0.09);
            height:12px;
        }

        > li {
            position: relative;

            a, button {
                font-weight:400;
                padding:12px 20px;
                position: relative;

                &[data-expander] {
                    &:before {
                        content:'';
                        position: absolute;
                        right:23px;
                        width:8px;
                        height:1px;
                        background:#000;
                        transform: rotate(45deg);
                        top:18px;
                    }
                    &:after {
                        content:'';
                        position: absolute;
                        right:23px;
                        width:8px;
                        height:1px;
                        background:#000;
                        transform: rotate(-45deg);
                        top:23px;
                    }
                }
                
            }
        }
    }

    // main nav flyout
    .main-navigation-mega__flyout {
        padding: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: #fff;
        z-index: 100;
        display:none;

        &.active {
            display:block !important;

            &:before {
                content:'';
                position: fixed;
                top:0;
                left:0;
                width:100%;
                height:100%;
                background:#ffffff;
            }
        }

        .main-navigation-mega__flyout-back {
            padding:0 0 0 10px;
            color:$color-primary;
            font-weight:700;
            @include x-rem(font-size, 14px);
            position: relative;

            &:before {
                content:'';
                position: absolute;
                left:0px;
                width:6px;
                height:1px;
                background:$color-primary;
                transform: rotate(45deg);
                top:10px;
            }
            &:after {
                content:'';
                position: absolute;
                left:0px;
                width:6px;
                height:1px;
                background:$color-primary;
                transform: rotate(-45deg);
                top:6px;
            }
        }
        .main-navigation-mega__flyout-inner {
            position: relative;

            > ul {
                padding:30px 0 0;

                li {
                    margin:0 0 15px;

                    a {
                        font-weight: normal;
                        padding:0;
                    }

                    ul {
                        padding:10px 0 0 0;

                        li {

                            a {
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
    }
    .main-navigation-mega__ctas {
        padding:20px 0 0 0;
    }
    .main-navigation-mega__icons {
        display:flex;
        flex-wrap: wrap;
    }
    .main-navigation-mega__icons-item {
        width:50%;

        &:nth-child(2) {
            border-left:1px solid #ccc;
        }
        &:nth-child(3) {
            border-top:1px solid #ccc;
        }
        &:nth-child(4) {
            border-top:1px solid #ccc;
            border-left:1px solid #ccc;
        }
    }
    .main-navigation-mega__icon {
        display:block;
        padding:20px 20px;
        text-align: center;
        color:#000;
        height:174px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        svg {
            width:64px;
            height:64px;
            margin:0 0 15px;
        }

        &.main-navigation-mega__icon--citrus {
            svg {
                fill:$color-orange;
            }
        }
        &.main-navigation-mega__icon--vibrant-blue {
            svg {
                fill:$color-primary;
            }
        }
        &.main-navigation-mega__icon--seaweed {
            svg {
                fill:$color-green;
            }
        }
        &.main-navigation-mega__icon--moss {
            svg {
                fill:$color-teal;
            }
        }
        &.main-navigation-mega__icon--gold {
            svg {
                fill:$color-gold;
            }
        }
        &.main-navigation-mega__icon--lupine {
            svg {
                fill:$color-purple;
            }
        }
        &.main-navigation-mega__icon--waves {
            svg {
                fill:$color-light-blue;
            }
        }
        &.main-navigation-mega__icon--deep-moss {
            svg {
                fill:$color-green3;
            }
        }
        &.main-navigation-mega__icon--tomato {
            svg {
                fill:$color-red;
            }
        }
        &.main-navigation-mega__icon--grass {
            svg {
                fill:$color-green2;
            }
        }
        &.main-navigation-mega__icon--ice {
            svg {
                fill:$color-ice;
            }
        }
        &.main-navigation-mega__icon--lilac {
            svg {
                fill:$color-lilac;
            }
        }
        &.main-navigation-mega__icon--lobster {
            svg {
                fill:$color-lobster;
            }
        }
        &.main-navigation-mega__icon--stone {
            svg {
                fill:$color-stone;
            }
        }
        &.main-navigation-mega__icon--granite {
            svg {
                fill:$color-granite;
            }
        }
        
    }

    .main-navigation-mega__ctas-image {
        display:block;
        position: relative;
        border-radius: 10px;
        background-size: cover;
        background-position: 50% 50%;

        &:before {
            content: "";
            display: block;
            padding-top: percentage(200/336);
        }
    }
    .main-navigation-mega__icons + .main-navigation-mega__ctas-image {
        margin:22px 0 0 0;
    }

    @media (min-width: $bp-mw) {
        position: static;
        display:flex;
        align-items: center;

        .main-navigation-simple {
            border-left:1px solid #cccccc;
            margin-left:10px;
            padding-left:10px;

            > li {

                > a,
                > button {
                    font-weight:400;
                }
            }
        }

        // 1st Level
        // ********************************************* //
        > ul {
            display:flex;
            justify-content: space-between;
            background:none;

            > li {
                flex:1;
                position: relative;

                &[data-type="mega"] {
                    position: static;
                }

                > a,
                > button {
                    text-align: center;
                    background:none;
                    @include x-rem(font-size, 13px);
                    white-space: nowrap;
                    padding:0px 10px;
                    font-weight:700;

                    &:hover,
                    &:focus {

                    }

                    &[data-expander-toggle] {
                        position: relative;
                        padding-right:26px;
            
                        // Arrows
                        &:before {
                            right:10px;
                        }
                        &:after {
                            right:5px;
                        }
            
                        &.active {
                            &:after {
                                
                            }
                        }
                    }
                }

                // 2nd Level
                // ********************************************* //
                > ul {
                    position:absolute;
                    width:100%;
                    top:calc(100% + 5px);
                    background:#ffffff;
                    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
                    border-radius: 0 0 5px 5px;
                    padding:12px 15px;

                    &:before,
                    &:after {
                        display:none;
                    }

                    > li {
                        margin:3px 0;

                        > a,
                        > button {
                            @include x-rem(font-size, 13px);
                            padding:5px 0;

                            &:hover,
                            &:focus {
                                
                            }
                        }
            
                        // 3rd Level
                        // ********************************************* //
                        
                        > ul {
                            background:#555555;
        
                            > li {
        
                                > a,
                                > button {
        
                                    &:hover,
                                    &:focus {
                                        text-decoration: none;
                                        background:$color-secondary;
                                    }
                                }       
                                
                                // 4th Level
                                // ********************************************* //
                                
                                > ul {
                                    background:#333333;
                
                                    > li {
                
                                        > a,
                                        > button {
                
                                            &:hover,
                                            &:focus {
                                                text-decoration: none;
                                                background:$color-secondary;
                                            }
                                        }                                
                                    }
                                }
                            }
                        }
                    }
                }
            }
            // Mega
            // ********************************************* //
            [data-nav-type="mega"] {
                position: static;

                [data-expander-target].target-show {
                    animation-name: toggleTargetShow;
                }
            
                [data-expander-target].target-hide {
                    animation-name: toggleTargetHide;
                }

                .main-navigation-mega {
                    position: absolute;
                    top:100%;
                    left:0;
                    width:100%;
                    background:#ffffff;
                    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
                    overflow: hidden;
                    padding:0 15px;

                    [data-expander-target].target-show {
                        animation-name: toggleTargetShowSide;
                        animation-fill-mode: none;
                    }
                
                    [data-expander-target].target-hide {
                        animation-name: toggleTargetHideSide;
                        animation-fill-mode: none;
                    }

                    &:before {
                        content:'';
                        position: absolute;
                        box-shadow:inset 0px 4px 6px rgba(0, 0, 0, 0.05);
                        height:12px;
                        top:0;
                        left:0;
                        width:100%;
                        z-index: 11;
                    }
                    

                    .main-navigation-mega__inner {
                        max-width:1486px;
                        margin:0 auto;
                        display:flex;

                        > ul {
                            width:320px;
                            box-shadow: 14px 0px 24px -13px rgba(0, 0, 0, 0.18);
                            background:#ffffff;
                            position: relative;
                            z-index:10;
                            padding:40px 20px 40px 0;

                            &:before, &:after {
                                display:none;
                            }

                            > li {
                                position: relative;
                                margin:7px 0;
                    
                                a, button {
                                    font-weight:400;
                                    padding:11px 22px;
                                    position: relative;
                                    @include x-rem(font-size, 20px);
                                    
                                    &[data-expander-toggle] {
                                        &:before {
                                            content:'';
                                            position: absolute;
                                            right:23px;
                                            width:8px;
                                            height:2px;
                                            background:#000;
                                            transform: rotate(45deg);
                                            top:18px;
                                        }
                                        &:after {
                                            content:'';
                                            position: absolute;
                                            right:23px;
                                            width:8px;
                                            height:2px;
                                            background:#000;
                                            transform: rotate(-45deg);
                                            top:23px;
                                        }
                                    }

                                    &:hover,
                                    &:focus {
                                        color:$color-primary;
                                        text-decoration: none;

                                        &:before {
                                            background:$color-primary;
                                        }
                                        &:after {
                                            background:$color-primary;
                                        }
                                    }

                                    &.active {
                                        background:#F2F2F2;
                                        color:$color-primary;
                                        border-radius: 6px;

                                        &:before {
                                            background:$color-primary;
                                        }
                                        &:after {
                                            background:$color-primary;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .main-navigation-mega__flyout {
                        padding: 28px 0 28px 50px;
                        display:none;
                        position: static;
                        background:none;
                        flex:1;
                        position: relative;
                        z-index: 9;
                        
                        &.active {
                            display:block !important;

                            &:before {
                                display:none;
                            }
                        }
                        &.target-hide.active {
                            display:none !important;
                            animation-duration:0;
                            animation-name:none;
                        }
                
                        .main-navigation-mega__flyout-back {
                            display:none;
                        }
                        .main-navigation-mega__flyout-inner {
                            display:flex;
                            justify-content: space-between;

                            > ul {
                                padding:22px 0 10px;
                    
                                li {
                                    margin:10px 0;
                    
                                    a {
                                        font-weight: normal;
                                        padding:0;
                                    }
                    
                                    ul {
                                        padding:0;
                    
                                        li {
                    
                                            a {
                                                font-weight: 400;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .main-navigation-mega__ctas {
                            padding:0 0 0 10%;
                            border-left:1px solid #CCCCCC;
                            width:40%;
                            min-width:390px;
                        }
                        .main-navigation-mega__icons {
                            display:flex;
                            flex-wrap: wrap;
                        }
                        .main-navigation-mega__icons-item {
                            width:50%;
                    
                            &:nth-child(2) {
                                border-left:1px solid #ccc;
                            }
                            &:nth-child(3) {
                                border-top:1px solid #ccc;
                            }
                            &:nth-child(4) {
                                border-top:1px solid #ccc;
                                border-left:1px solid #ccc;
                            }
                        }
                        .main-navigation-mega__icon {
                            display:block;
                            padding:20px 20px;
                            text-align: center;
                            color:#000;
                            height:174px;
                            display: flex;
                            flex-flow: column;
                            justify-content: center;
                            align-items: center;
                    
                            svg {
                                width:64px;
                                height:64px;
                                margin:0 0 15px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (min-width: $bp-l) {

        .main-navigation-simple {
            margin-left:10px;
            padding-left:10px;
            padding-top:2px;

            > li {

                > a,
                > button {
                    @include x-rem(font-size, 16px);
                }
            }
        }

        // 1st Level
        // ********************************************* //
        > ul {

            > li {

                > a,
                > button {
                    @include x-rem(font-size, 18px);
                }
            }
        }
    }
    @media (min-width: 1300px) {

        .main-navigation-simple {
            margin-left:15px;
            padding-left:20px;
            padding-top:2px;

            > li {
                padding:0 8px;

                > a,
                > button {
                    @include x-rem(font-size, 16px);
                }
            }
        }

        // 1st Level
        // ********************************************* //
        > ul {

            > li {
                padding:0 20px;

                > a,
                > button {
                    @include x-rem(font-size, 18px);
                }
            }
        }
    }
}