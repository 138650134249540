.card-listing {
    @include resetList;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.card-item {
    width: 50%;
    padding: 0 7.5px;
    margin: 0 0 20px;

    &.card-item--stand-alone {
        width:auto;
        padding:0;
        margin:0 0 30px;
    }
}

.card-item__inner {
    display: block;
    line-height: 1.4;
    background:#fff;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    min-height:160px;
    height:100%;
    padding:25px 15px 15px;
    text-decoration: none;
    color:#000;
    text-align: center;

    &:hover,
    &:focus {
        color: $color-primary;
        text-decoration: underline;
    }

    
}

.card-item__title {
    font-weight:700;
    @include x-rem(font-size, 18px);
    margin: 0;
    line-height:1.3;
}

.card-item__icon {
    width:60px;
    height:60px;
    margin:0 0 15px;

    &.card-item__icon--citrus {
        fill:$color-orange;
    }
    &.card-item__icon--vibrant-blue {
        fill:$color-primary;
    }
    &.card-item__icon--seaweed {
        fill:$color-green;
    }
    &.card-item__icon--moss {
        fill:$color-teal;
    }
    &.card-item__icon--gold {
        fill:$color-gold;
    }
    &.card-item__icon--lupine {
        fill:$color-purple;
    }
    &.card-item__icon--waves {
        fill:$color-light-blue;
    }
    &.card-item__icon--deep-moss {
        fill:$color-green3;
    }
    &.card-item__icon--tomato {
        fill:$color-red;
    }
    &.card-item__icon--grass {
        fill:$color-green2;
    }
    &.card-item__icon--spring {
        fill:$color-green4;
    }
    &.card-item__icon--ice {
        fill:$color-ice;
    }
    &.card-item__icon--lilac {
        fill:$color-lilac;
    }
    &.card-item__icon--lobster {
        fill:$color-lobster;
    }
    &.card-item__icon--stone {
        fill:$color-stone;
    }
    &.card-item__icon--granite {
        fill:$color-granite;
    }
}

.card-listing--grid {
    flex-wrap: wrap;
    justify-content: flex-start;

    .card-item {
        width:50%;
        margin:0;
        padding:0;
        border-bottom:1px solid #cccccc;
        border-right:1px solid #cccccc;

        

        &:nth-child(2n) {
            border-right:0;
        }
        @media (max-width: $bp-mw-max) {
            &.card-item--m-last {
                border-bottom: 0;

                &:nth-child(2n) {
                    border-bottom:1px solid #cccccc;

                    &:last-child {
                        border-bottom:0;
                    }
                }
            }
        }
    }
    .card-item__inner {
        box-shadow: none;
        border-radius: 0;
        background:transparent;
        min-height:174px;
        padding:35px 12px 15px;
        display:flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
    }
}

@media (min-width: $bp-m) {

    .card-item {
        width: 33.33%;
        padding:0 20px;
    }
    .card-item__inner {
        min-height:260px;
        padding:65px 15px 15px;
    }
    .card-item__icon {
        width:80px;
        height:80px;
        margin:0 0 30px;
    }
    .card-item__title {
        @include x-rem(font-size, 24px);
    }
    
}
@media (min-width: $bp-l) {
    .card-listing {
        flex-wrap: nowrap;
    }

    .card-item {
        flex: 1 0 0px;
        width: auto;
    }

    .card-listing--grid {
        flex-wrap: wrap;
        
        .card-item {
            width:33.33%;
            flex:0 1 auto;

            &:nth-child(2n) {
                border-right:1px solid #cccccc;
            }
            &:nth-child(3n) {
                border-right:0;
            }
            &.card-item--d-last {
                border-bottom:0;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    border-bottom:1px solid #cccccc;
                }
            }
        }
        .card-item__inner {
            box-shadow: none;
            border-radius: 0;
            background:transparent;
            min-height:280px;
            padding:45px 12px 15px;
        }
    }
}