/* ================================================================
   Typography Styles
   ================================================================ */

@font-face {
	font-family: "Akkurat";
	src: url("../fonts/AkkuratLLWeb-Thin.woff2") format("woff2"),
		 url("../fonts/AkkuratLLWeb-Thin.woff") format("woff");
	font-weight:300;
	font-style: normal;
}
@font-face {
	font-family: "Akkurat";
	src: url("../fonts/AkkuratLLWeb-ThinItalic.woff2") format("woff2"),
		 url("../fonts/AkkuratLLWeb-ThinItalic.woff") format("woff");
	font-weight:300;
	font-style: italic;
}
@font-face {
	font-family: "Akkurat";
	src: url("../fonts/AkkuratLLWeb-Light.woff2") format("woff2"),
		 url("../fonts/AkkuratLLWeb-Light.woff") format("woff");
	font-weight:400;
	font-style: normal;
}
@font-face {
	font-family: "Akkurat";
	src: url("../fonts/AkkuratLLWeb-LightItalic.woff2") format("woff2"),
		 url("../fonts/AkkuratLLWeb-LightItalic.woff") format("woff");
	font-weight:400;
	font-style: italic;
}
@font-face {
	font-family: "Akkurat";
	src: url("../fonts/AkkuratLLWeb-Regular.woff2") format("woff2"),
		 url("../fonts/AkkuratLLWeb-Regular.woff") format("woff");
	font-weight:normal;
	font-style: normal;
}
@font-face {
	font-family: "Akkurat";
	src: url("../fonts/AkkuratLLWeb-Italic.woff2") format("woff2"),
		 url("../fonts/AkkuratLLWeb-Italic.woff") format("woff");
	font-weight:normal;
	font-style: italic;
}
@font-face {
	font-family: "Akkurat";
	src: url("../fonts/AkkuratLLWeb-Bold.woff2") format("woff2"),
		 url("../fonts/AkkuratLLWeb-Bold.woff") format("woff");
	font-weight:bold;
	font-style: normal;
}
@font-face {
	font-family: "Akkurat";
	src: url("../fonts/AkkuratLLWeb-BoldItalic.woff2") format("woff2"),
		 url("../fonts/AkkuratLLWeb-Bolditalic.woff") format("woff");
	font-weight:bold;
	font-style: italic;
}
@font-face {
	font-family: "Akkurat";
	src: url("../fonts/AkkuratLLWeb-Black.woff2") format("woff2"),
		 url("../fonts/AkkuratLLWeb-Black.woff") format("woff");
	font-weight:900;
	font-style: normal;
}
@font-face {
	font-family: "Akkurat";
	src: url("../fonts/AkkuratLLWeb-BlackItalic.woff2") format("woff2"),
		 url("../fonts/AkkuratLLWeb-BlackItalic.woff") format("woff");
	font-weight:900;
	font-style: italic;
}

h1, .h1, h2, .h2, h3, .h3 {
	margin:0 0 $typography-margin-bottom;
	font-family:$font-heading;
}

h1, .h1 { @include x-rem(font-size, 37px); }
h2, .h2 { @include x-rem(font-size, 32px); font-weight:400}
h3, .h3 { @include x-rem(font-size, 18px); }
h4, .h4 { font-family: $font-primary }

.heading {
	text-align: center;
}

.section-description {
	max-width: 1100px;
	margin:0 auto 40px;

	*:last-child {
		margin:0;
	}
}

a {
	color:$color-primary;
	text-decoration:underline;
	transition: $trans;

	&:hover, &:focus { text-decoration:none; }
}

.cta-arrow {
	font-weight:700;
	text-decoration: none;

	&:after {
		content:'';
		background-image:url(../images/blue-arrow.svg);
		width:14px;
		height:11px;
		margin:0 0 0 6px;
		display:inline-block;
	}

	&.cta-arrow--black {
		color:#000;

		&:after {
			background-image:url(../images/black-arrow.svg);
		}
	}

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

p { margin:0 0 $typography-margin-bottom; line-height:1.5; }

// Very simple blockquote styling
blockquote {
	margin:1em 1.5em; padding-left:1.5em;
	border-left:5px solid hsl(0, 0%, 85%);
}

.smallest { font-size:0.8em; }
.smaller { font-size:0.9em; }
.larger { font-size:1.1em; }
.largest { font-size:1.2em; }

.bold { font-weight:bold; }
.italic { font-style:italic; }
.strike { text-decoration:line-through; }

.nowrap { white-space:nowrap; }
.important { color:red; font-weight:bold; text-transform:uppercase; }

.title {
	@include fluid-type($bp-s, $bp-mw, 20px, 30px);
	font-weight: $fontWeight-semibold;
	margin: 0 0 5px;
}

.sub-title {
	@include fluid-type($bp-s, $bp-mw, 20px, 28px);
	font-weight: 400;
	margin: 0 0 15px;
}


@media (min-width: $bp-mw) {
	h2, .h2 { @include x-rem(font-size, 48px); }
	p, ul, ol {
		@include x-rem(font-size, 18px);
	}
}