
.compare-list {
    margin:0;
    padding: 0;
    list-style: none;
}
.compare-list__item {
    margin:0 0 30px
}
.compare-block {
    border-radius: 20px;
    padding:30px 20px 30px;
    border-top:20px solid $color-primary;
    background: #fff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);

    &.compare-block--citrus {
        border-color:$color-orange;
    }
    &.compare-block--vibrant-blue {
        border-color:$color-primary;
    }
    &.compare-block--seaweed {
        border-color:$color-green;
    }
    &.compare-block--moss {
        border-color:$color-teal;
    }
    &.compare-block--gold {
        border-color:$color-gold;
    }
    &.compare-block--lupine {
        border-color:$color-purple;
    }
    &.compare-block--waves {
        border-color:$color-light-blue;
    }
    &.compare-block--deep-moss {
        border-color:$color-green3;
    }
    &.compare-block--tomato {
        border-color:$color-red;
    }
    &.compare-block--grass {
        border-color:$color-green2;
    }
    &.compare-block--spring {
        border-color:$color-green4;
    }
    &.compare-block--ice {
        border-color:$color-ice;
    }
    &.compare-block--lilac {
        border-color:$color-lilac;
    }
    &.compare-block--lobster {
        border-color:$color-lobster;
    }
    &.compare-block--stone {
        border-color:$color-stone;
    }
    &.compare-block--granite {
        border-color:$color-granite;
    }
}
.compare-block__inner {
    margin:0 0 15px;
}
.compare-block__heading {
    font-weight:400;
    @include x-rem(font-size, 24px);
    margin: 0 0 15px;
}
.compare-block__description {
    font-weight:400;
    @include x-rem(font-size, 16px);
    line-height:1.5;

    p {
        font-weight:400;
        @include x-rem(font-size, 16px);
        line-height:1.5;
    }
    
    *:last-child {
        margin:0;
    }
}
.compare-block__cta {
    padding:30px 0 0;
    margin:0 0 25px;

    .button {
        display: block;
    }
}

@media (min-width: $bp-m) {
    .compare-list {
        display:flex;
        flex-wrap: wrap;
    }
    .compare-list__item {
        width:50%;
        padding:0 15px;
    }
}
@media (min-width: 1140px) {
    .compare-list__item {
        width:25%;
        margin:0 0 30px;
    }
}