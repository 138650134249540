.bio-listing {
    list-style: none;
    margin:0;
    padding:0;
}
.bio-listing__item {
    margin:0 0 30px;
}
.bio-listing__block {
    text-decoration: none;
    border:0;
    background:none;
    padding:0;
    width: 100%;
    text-align: left;
    cursor: pointer;

    &:hover,
    &:focus {
        .cta-arrow {
            text-decoration: underline;
        }
    }
    .cta-arrow {
        color:$color-primary;
    }
}
.bio-listing__image {
    position: relative;
    background-size: cover;
    background-position: 50% 50%;
    border-radius:20px;
    margin:0 0 15px;

    &:before {
        content: "";
        display: block;
        padding-top: percentage(400/336);
    }
}
.bio-listing__heading {
    font-weight:400;
    @include x-rem(font-size, 24px);
    margin: 0 0 15px;
    color:#000;
}
.bio-listing__title {
    font-weight:400;
    @include x-rem(font-size, 16px);
    margin:0 0 15px;
    color:#000;
}

@media (min-width: 600px) {
    .bio-listing {
        display:flex;
        flex-wrap: wrap;
        margin:0 -15px;
    }
    .bio-listing__item {
        width:50%;
        padding:0 15px;
    }
}
@media (min-width: $bp-mw) {
    .bio-listing__item {
        width:33.33%;
    }
}
@media (min-width: $bp-l) {
    .bio-listing__item {
        width:25%;
    }
}

/* Bio Modal */

.bio-details {
    text-align: left;
    max-width: 760px;
}
.bio-details__top {
    margin:0 0 20px;
}
.bio-details__image {
    margin:0 0 20px;

    img {
        border-radius: 20px;
    }
}
.bio-details__name {
    font-weight:400;
    @include x-rem(font-size, 28px);
    margin: 0 0 15px;
}

.bio-details__contact {
    margin:0 0 12px;
}
.bio-details__contact-link {
    display:inline-block;
    padding:0 0 0 30px;
    position: relative;

    svg {
        width:20px;
        height:20px;
        fill: #091D6B;
        position: absolute;
        left:0;
        top:0;
    }
}

.bio-details__bio {
    margin:0 0 20px;

    &:last-child {
        margin:0;
    }
}
.bio-details__heading {
    margin:0 0 5px;
}

@media (min-width: 660px) {
    .bio-details__top {
        display:flex;
    }
    .bio-details__image {
        flex:1;
        padding-right:20px;
        margin:0;
    }
    .bio-details__right {
        flex:1;
    }
}