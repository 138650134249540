
.tabs-section {

}
.tabs-section-list {
    list-style: none;
    padding:0;
    margin:0;
    border-bottom:1px solid #cccccc;
    display:none;
}
.tabs-section-list__item {
    margin:0 20px 0 0;
    width: 100%;
    max-width: 415px;
}
.tabs-section-button {
    background:#eaeaea;
    color:#444444;
    border-radius: 8px 8px 0 0;
    padding:20px;
    width: 100%;
    font-weight:400;
    @include x-rem(font-size, 24px);
    border:0;
    cursor:pointer;

    &.active {
        background:$color-primary-alt;
        color:#ffffff;
    }
}

.tabs-section-content {
    display:none;
    padding:30px 0 0;

    &.active {
        display:block;
    }
}

.tabs-section-mobile {
    position: relative;
    max-width: 340px;
}
.tabs-section-mobile__dropdown {
    border:1px solid #808080;
    border-radius: 6px;
    position: relative;
    width: 100%;
    background:#fff;
    height: 50px;
    padding: 8px 20px;
    font-weight: 400;
    font-family: $font-primary;
    text-align: left;
    cursor: pointer;

    &:before {
        content:'';
        position: absolute;
        top:50%;
        width:10px;
        height:2px;
        right:13px;
        background:#000;
        transform: rotate(45deg);
    }
    &:after {
        content:'';
        position: absolute;
        top:50%;
        width:10px;
        height:2px;
        right:7px;
        background:#000;
        transform: rotate(-45deg);
    }

    &.active {
        &:before {
            top:50%;
            transform: rotate(-45deg);
        }
        &:after {
            top:50%;
            transform: rotate(45deg);
        }
    }
}
.tabs-section-mobile__list {
    list-style: none;
    padding: 0;
    margin:0;
    position: absolute;
    width: 100%;
    z-index: 100;
    top:calc(100% - 5px);
    border:1px solid #808080;
    border-top:0;
    background:#fff;
    border-radius: 0 0 6px 6px;
    overflow: hidden;
}
.tabs-section-mobile__list-item {

}
.tabs-section-mobile__list-button {
    width: 100%;
    padding:10px;
    font-weight:400;
    background:none;
    border:0;
    text-align: left;
    padding: 10px 20px;
    font-weight: 400;
    font-family: $font-primary;

    &.active {
        background:$color-primary;
        color:#fff;
    }
}

@media (min-width: $bp-mw) {
    .tabs-section-list {
        display:flex;
    }
    .tabs-section-mobile {
        display:none;
    }
    .tabs-section-content {
        padding:60px 0 0 ;
    }
}