/* ================================================================
   Hero Slider and Static
   ================================================================ */

.hero-slider-container {
    position: relative;
    padding:20px 0 0;
}

.hero-slider-container__inner {
    padding:0 15px;
    max-width:1720px;
    margin:0 auto;
}

.hero-slider {
    display: flex;
    align-items: flex-start;
    overflow-y: hidden;
}

.hero-slide {
    width: 100%;
    flex: 1 0 auto;
    background: no-repeat center / cover;
}

.hero-slide__inner {
    
}

.hero-slide__img-container {
    margin:0 0 15px 0;
    position: relative;

    &.hero-slide__img-container--mask {
        padding:0 25px 25px 25px;

        &:before {
            content:'';
            position: absolute;
            width:100%;
            height:100%;
            top:0;
            left:0;
            background:url(../images/circle-blue.png);
            background-size: 100% 100%;
        }


        &.hero-slide__img-container--citrus {
            &:before {
                background-image:url(../images/circle-citrus.png);
            }
        }
        &.hero-slide__img-container--vibrant-blue {
            &:before {
                background-image:url(../images/circle-vibrant-blue.png);
            }
        }
        &.hero-slide__img-container--seaweed {
            &:before {
                background-image:url(../images/circle-seaweed.png);
            }
        }
        &.hero-slide__img-container--moss {
            &:before {
                background-image:url(../images/circle-moss.png);
            }
        }
        &.hero-slide__img-container--gold {
            &:before {
                background-image:url(../images/circle-buttercup.png);
            }
        }
        &.hero-slide__img-container--lupine {
            &:before {
                background-image:url(../images/circle-lupine.png);
            }
        }
        &.hero-slide__img-container--waves {
            &:before {
                background-image:url(../images/circle-waves.png);
            }
        }
        &.hero-slide__img-container--deep-moss {
            &:before {
                background-image:url(../images/circle-deep-moss.png);
            }
        }
        &.hero-slide__img-container--tomato {
            &:before {
                background-image:url(../images/circle-tomato.png);
            }
        }
        &.hero-slide__img-container--grass {
            &:before {
                background-image:url(../images/circle-grass.png);
            }
        }
        &.hero-slide__img-container--spring {
            &:before {
                background-image:url(../images/circle-spring.png);
            }
        }
        &.hero-slide__img-container--ice {
            &:before {
                background-image:url(../images/circle-ice.png);
            }
        }
        &.hero-slide__img-container--lilac {
            &:before {
                background-image:url(../images/circle-lilac.png);
            }
        }
        &.hero-slide__img-container--lobster {
            &:before {
                background-image:url(../images/circle-lobster.png);
            }
        }
        &.hero-slide__img-container--stone {
            &:before {
                background-image:url(../images/circle-stone.png);
            }
        }
        &.hero-slide__img-container--granite {
            &:before {
                background-image:url(../images/circle-granite.png);
            }
        }
    }
}
.hero-slide__img {
    background: no-repeat center / cover;
    position: relative;
    
    &::after {
        content: "";
        display: block;
        padding-top: percentage(798/884);
    }
}

.hero-slide__content {

}

.hero-slide__title {
    font-weight:400;
    @include x-rem(font-size, 42px);
    margin: 0 0 15px;
    line-height:1.1;
}

.hero-slide_subtitle {
    @include x-rem(font-size, 22px);
    font-weight: 700;
    margin: 0 0 10px;
    line-height: 1.3;
}

.hero-slide__description {
    @include x-rem(font-size, 16px);
    font-weight: 400;
    margin: 0 0 15px;
    line-height: 1.6;
}

.hero-slide__button {
    display: block;
}

.hero-slider-controls {
    display:flex;
    justify-content: center;
    padding:20px 0;

    .slick-dots {
        list-style: none;
        padding:0;
        margin:0;
        display:flex;
        justify-content: center;

        li {
            padding:0 15px;

            button {
                width:12px;
                height:12px;
                background:#808080;
                border-radius: 50%;
                border:0;
                padding:0;
                text-indent: -9999em;
                cursor: pointer;

                &:hover,
                &:focus {
                    background:$color-primary;
                }
            }

            &.slick-active {
                button {
                    background:$color-primary;
                }
            }
        }
    }
}
.hero-slider-pause {
    padding:0;
    border: none;
    font-size: 0;
    cursor: pointer;
    box-sizing: border-box;
    z-index: 100;
    background:none;
    display:none;

    &::before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;       
        box-sizing: border-box;
        transition: $trans;
        border-style: double;
        border-width: 0 0 0 12px;
        border-color:transparent transparent transparent #808080;
    }

    &.active:before {
        border-style: solid;
        border-width: 7px 0 7px 11px;
        border-color: transparent transparent transparent $color-primary;
    }

    &:hover,
    &:focus {
        
    }
}

@media (min-width: $bp-mw) {
    .hero-slider-container {
        margin: 0;
    }
    .hero-slide__img-container {
        order:2;
        flex:1;
        margin: 0 0 0 10%;
    }
    .hero-slide__inner {
        position: relative;
        display: flex;
        overflow: hidden;
        align-items: center;
    }
    .hero-slide__content {
        width:40%;
        padding:0 0 120px;
    }
    .hero-slide__title {
        @include x-rem(font-size, 46px);
        margin: 0 0 10px;
    }
    
    .hero-slide_subtitle {
        @include x-rem(font-size, 24px);
        margin: 0 0 10px;
    }
    
    .hero-slide__description {
        @include x-rem(font-size, 18px);
        margin: 0 0 15px;
        max-width: 520px;
    }
    .hero-slide__button {
        display:inline-block;
    }

    .hero-slider-controls {
        justify-content: flex-start;
        margin:-100px 0 0 0;
        position: relative;
    }
    .hero-slider-pause {
        display:block;
        order:2;
        margin:0 0 0 10px;
    }
}

@media (min-width: $bp-l) {
    .hero-slide__title {
        @include x-rem(font-size, 64px);
        margin: 0 0 10px;
    }
}

@media (min-width: $bp-xl) {
    .hero-slide__title {
        @include x-rem(font-size, 86px);
        margin: 0 0 10px;
    }
}