/* ================================================================
   Variables
   ================================================================ */

$input-height: 40px !default;
$input-background: #ffffff !default;
$input-border-color: $color-primary !default;
$input-border-color-selected: $color-secondary !default;
$input-border-width: 2px !default;
$input-padding: 5px 10px;
$form-spacing: 10px;
$note-size: 14px;
$error-color: red;
$info-success: green;
$info-color: #225894;

/* ================================================================
   Required
   ================================================================ */

.required-indicator {
	position:relative;
	padding-left:10px;

	&:before {
		display:block; content:'*';
		position:absolute; left:0; top:0;
		color:red;
	}
}

/* ================================================================
   Controls/Inputs
   ================================================================ */

label {
	vertical-align: middle;
}

input {
	padding: $input-padding;
	@include x-rem(font-size, 16px);
	border: $input-border-width solid $input-border-color;
	border-radius:0;
	vertical-align: middle;
	height: $input-height;
	max-width: 100%;
	transition: all 0.2s ease-in-out;

	&:hover,
	&:focus {
		border-color: $input-border-color-selected;
	}
}

input[type="checkbox"],
input[type="radio"] {
	margin-right: 5px;
	border-radius: 0;
	vertical-align: middle;
	height: auto;
}

// File inputs are very tricky cross browser so recreating height using padding
input[type="file"] {
	height: auto;
	padding-top: 0.345rem;
	padding-bottom: 0.345rem;
}

select {
	border: $input-border-width solid $input-border-color;
	vertical-align: middle;
	height: $input-height;
	padding: $input-padding;
	max-width: 100%;

	&[multiple] { height: auto; }

	&:hover,
	&:focus {
		border-color: $input-border-color-selected;
	}
}

textarea {
	padding: $input-padding;
	min-height: 100px;
	border: $input-border-width solid $input-border-color;
	border-radius:0;
	background: $input-background;
	max-width: 100%;
	transition: all 0.2s ease-in-out;

	&:hover,
	&:focus {
		border-color: $input-border-color-selected;
	}
}

button {
	vertical-align: middle;
}

/* ================================================================
   Kentico CMS
   ================================================================ */

.InfoLabel {
	color: $info-color;
}

.ErrorLabel {
	color: $error-color;
}

.EditingFormErrorLabel {
	display: block;
	color: $error-color;
	margin: $form-spacing 0 0 0;
}

.FormPanel {
	display: block;
	max-width: $bp-sw;

	table.EditingFormTable {
		width: 100%;

		thead,
		tbody,
		tr,
		td { display: block; }

		tr {
			padding: $form-spacing 0;
			border-top: 1px solid #b8b8b8;

			&:first-child { border-top: none; }
		}

		label,
		.EditingFormLabel {
			display: block;
			margin: 0 0 $form-spacing;
			font-weight: bold;
		}

		input,
		select,
		textarea {
			width: 100%;

			&[type="checkbox"],
			&[type="radio"] {
				width: auto;
			}
		}

		.ExplanationText {
			margin: $form-spacing 0 0 0;
			@include x-rem(font-size, $note-size);
		}

		.checkbox { display: inline-block; }

		// Single checkbox styling
		.single-checkbox-field {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: center;

			label { margin: 0 $form-spacing 0 0; }

			.EditingFormValueCell {
				font-size: 0;
			}

			.ExplanationText {
				@include x-rem(font-size, $note-size);
			}
		}

		// Multiple checkbox styling
		.multi-checkbox-field {
			label {
				display: inline-block;
				margin: 0;
				font-weight: normal;
			}

			input {
				display: inline-block;
				width: auto;
			}
		}

		// Radio list styling
		.radio-list-field {
			label {
				display: inline-block;
				margin: 0;
				font-weight: normal;
			}

			input {
				display: inline-block;
				width: auto;
			}
		}

		// Date picker styling
		.date-picker-field {
			input { margin: 0 0 $form-spacing 0; }

			button {
				width: 100%;
				margin: 0 0 $form-spacing 0;
			}

			.calendar-action { display: none; }

			.form-control-text {
				@extend .btn;
				width: 100%;
			}
		}

		// Captcha
		.captcha-field {
			.CaptchaTable {
				width: 100%;
				margin: 0;
				font-size: 0;
				border-collapse: collapse;

				tr {
					border: none;
					padding: 0;
				}

				input {
					@include x-rem(font-size, 16px);
					margin: $form-spacing 0;
				}
			}
		}

		// Phone
		.phone-field {
			.editing-form-control-nested-control {
				 display: flex;
				 justify-content: space-between;
				 align-items: center;
				 max-width: $bp-s;

				 span {
					 flex: 0 0 auto;
					 margin: 0 5px;
				 }

				 input {
					 min-width: 0;
					 flex: 1 1 100%;
				 }
			}
		}

		// Media selection styling
		.MediaSelectorHeader {
			input:not(:last-child) { margin-bottom: $form-spacing; }
		}

		// Calendar
		.icon-calendar + .sr-only {
			display: inline-block;
			overflow: visible;
			position: static;
			clip: auto;
			width: auto;
			height: auto;
		}
	}
}

// jQuery date picker popup
#ui-datepicker-div {
	z-index: 8999 !important;

	.datetime-ui-datepicker-prev,
	.datetime-ui-datepicker-next {
		display: none;
	}

	@at-root html[class^="js-"] & .action-buttons {
		display: none;
	}
}

@media (min-width: $bp-mw) {
	.FormPanel {
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}
}

// form builder

div[id*='form-'],
.inline-form {

	p {
		@media (min-width: $bp-mw) {
			@include x-rem(font-size, 20px);
		}
	}

	&.inline-form--slim {
		max-width:660px;
		margin:0 0 20px;
	}
	
	form {
	  width: 100%;
	  margin: 0 auto;
	}

	.form-field {
		margin:0 0 30px;
	}

	label {
		display: block;
		font-weight:700;
		margin: 0 0 10px;
		@include x-rem(font-size, 14px);

		&.required-label {
			&:after {
				content:'*';
				color:#E32626;
				margin:0 0 0 3px;
			}
		}
	}
	input[type="text"],
	input[type="tel"],
	input[type="number"],
	input[type="email"],
	input[type="file"],
	input[type="password"],
	select,
	textarea {
		width: 100%;
		background:#fff;
		height:50px;
		border-radius: 6px;
		border:1px solid #808080;

		&[type="checkbox"],
		&[type="radio"] {
			width: auto;
		}

		&.input-validation-error {
			border:1px solid #E32626;
		}

		&:disabled {
			background:#eee;
		}

		&:hover,
		&:focus {
			border:1px solid $color-primary;
		}
	}
	textarea {
		height:150px;
	}
	input[type=checkbox], input[type=radio] {
		float:left;
		margin:4px 5px 5px 0;
	}
	.ktc-checkbox, .ktc-radio {
		display:flex;
		align-items: flex-start;
		margin:0 0 10px;

		label {
			width:100%;
			margin:0;
			font-weight: 400;
			@include x-rem(font-size, 18px);
		}

		input[type=checkbox] {
			margin:5px 5px 0 0;
			flex:1;
			min-width:30px;
		}
		input[type=radio] {
			margin:5px 5px 0 0;
			flex:1;
			min-width:30px; 
		}
	}
	.ktc-radio-list {
		display:block;

		.ktc-radio {

		}
	}

	.form-consent-explanation {
		margin:0 0 10px;

		> * {
			margin:0;
		}
	}
	.form-consent-area {
		height:150px;
	}
	.field-validation-error,
	.field-validation-valid {
		color:#E32626;
		font-style: italic;
		margin:3px 0 0 0;
		display:block;
	}

	.autocomplete-container {
		position: relative;

		ul {
			position: absolute;
			top:calc(100% - 1px);
			background: #fff;
			left: 6px;
			padding: 0;
			margin: 0;
			list-style: none;
			border:1px solid #808080;
			border-top:0;
			min-width: 200px;

			li {

			}
			button {
				background:none;
				border:0;
				padding:5px 10px;
				display:block;
				width:100%;
				text-align: left;
				cursor: pointer;

				&:hover,
				&:focus {
					background:$color-primary;
					color:#fff;
				}
			}
		}
	}

	input[type="submit"] {
		@extend .button;
		height:auto;
	}

	/* CMM */
	.cmm-form {

	}
	.cmm-form__heading {
		margin:0 0 15px;
		@include x-rem(font-size, 30px);
	}
	.cmm-form__sub-heading {
		margin:0 0 15px;
		@include x-rem(font-size, 22px);
		font-weight:400;
	}
	.cmm-form__help {
		margin:0 0 15px;
		@include x-rem(font-size, 16px);
	}
	.cmm-checkbox {
		@include x-rem(font-size, 18px);
		display:flex;
		align-items: center;

		input[type="checkbox"] {
			width:20px;
			height:20px;
			margin:0 8px 0 0;
		}
	}
}

