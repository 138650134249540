.interior-top {
    background:$color-gray-2;
    padding:10px 0 30px;

    &.interior-top--citrus {
        background:$color-orange;

        .breadcrumbs__link {
            color:#000;
        }
        .breadcrumbs__item:after {
            color:#000;
        }
        .breadcrumbs__active {
            color:#000;
        }
        .page-banner__content {
            color:#000;
        }
    }
    &.interior-top--vibrant-blue {
        background:$color-primary;

        .page-banner__content {
            color:#ffffff;
        }
    }
    &.interior-top--seaweed {
        background:$color-green;

        .breadcrumbs__link {
            color:#000;
        }
        .breadcrumbs__item:after {
            color:#000;
        }
        .breadcrumbs__active {
            color:#000;
        }
        .page-banner__content {
            color:#000;
        }
    }
    &.interior-top--moss {
        background:$color-teal;

        .page-banner__content {
            color:#ffffff;
        }
    }
    &.interior-top--gold {
        background:$color-gold;

        .breadcrumbs__link {
            color:#000;
        }
        .breadcrumbs__item:after {
            color:#000;
        }
        .breadcrumbs__active {
            color:#000;
        }
        .page-banner__content {
            color:#000;
        }
    }
    &.interior-top--lupine {
        background:$color-purple;

        .page-banner__content {
            color:#ffffff;
        }
    }
    &.interior-top--waves {
        background:$color-light-blue;

        .breadcrumbs__link {
            color:#000;
        }
        .breadcrumbs__item:after {
            color:#000;
        }
        .breadcrumbs__active {
            color:#000;
        }
        .page-banner__content {
            color:#000;
        }
    }
    &.interior-top--deep-moss {
        background:$color-green3;

        .page-banner__content {
            color:#ffffff;
        }
    }
    &.interior-top--tomato {
        background:$color-red;

        .page-banner__content {
            color:#ffffff;
        }
    }
    &.interior-top--grass {
        background:$color-green2;

        .breadcrumbs__link {
            color:#000;
        }
        .breadcrumbs__item:after {
            color:#000;
        }
        .breadcrumbs__active {
            color:#000;
        }
        .page-banner__content {
            color:#000;
        }
    }
    &.interior-top--spring {
        background:$color-green4;

        .breadcrumbs__link {
            color:#000;
        }
        .breadcrumbs__item:after {
            color:#000;
        }
        .breadcrumbs__active {
            color:#000;
        }
        .page-banner__content {
            color:#000 ;
        }
    }
    &.interior-top--ice {
        background:$color-ice;

        .breadcrumbs__link {
            color:#000;
        }
        .breadcrumbs__item:after {
            color:#000;
        }
        .breadcrumbs__active {
            color:#000;
        }
        .page-banner__content {
            color:#000 ;
        }
    }
    &.interior-top--lilac {
        background:$color-lilac;

        .breadcrumbs__link {
            color:#000;
        }
        .breadcrumbs__item:after {
            color:#000;
        }
        .breadcrumbs__active {
            color:#000;
        }
        .page-banner__content {
            color:#000 ;
        }
    }
    &.interior-top--lobster {
        background:$color-lobster;

        .breadcrumbs__link {
            color:#000;
        }
        .breadcrumbs__item:after {
            color:#000;
        }
        .breadcrumbs__active {
            color:#000;
        }
        .page-banner__content {
            color:#000 ;
        }
    }
    &.interior-top--stone {
        background:$color-stone;

        .breadcrumbs__link {
            color:#fff;
        }
        .breadcrumbs__item:after {
            color:#fff;
        }
        .breadcrumbs__active {
            color:#fff;
        }
        .page-banner__content {
            color:#fff ;
        }
    }
    &.interior-top--granite {
        background:$color-granite;

        .breadcrumbs__link {
            color:#000;
        }
        .breadcrumbs__item:after {
            color:#000;
        }
        .breadcrumbs__active {
            color:#000;
        }
        .page-banner__content {
            color:#000 ;
        }
    }
}

.page-banner {
    
}

.page-banner__image {
    background: no-repeat center / cover;
    margin:0 0 25px 0;
    position: relative;
    border-radius: 20px;

    &::after {
        content: "";
        display: block;
        padding-top: percentage(480/740);
    }
}

.page-banner__content {
    color:#ffffff;
}

.page-banner__heading {
    position: relative;
    @include x-rem(font-size, 32px);
    font-weight:400;
    margin:0;
    line-height:1.1;
}
.page-banner__description {
    font-weight:400;
    line-height:1.6;
    @include x-rem(font-size, 16px);
    margin:15px 0 0;

    *:last-child {
        margin:0;
    }
}

.page-banner__button {
    margin:15px 0 0 0;
}

@media (min-width: $bp-mw) {
    .interior-top {
        background:$color-gray-2;
        padding:25px 0 0;
    }
    
    .page-banner {
        position: relative;
        display:flex;
        align-items: center;
    }
    .page-banner__image {
        flex:1;
        order:2;
        margin:0 0 -40px;
    }
    

    .page-banner__heading {
        @include x-rem(font-size, 48px);
    }
    .page-banner__description {
        @include x-rem(font-size, 18px);
    }

    .page-banner__content {
        padding:20px 0 40px 0;
        width:80%;
    }
    .interior-top--has-image {
        .page-banner__content {
            width:50%;
            padding:0 5% 20px 0;
        }
    }
}

@media (min-width: $bp-l) {
    .page-banner__heading {
        @include x-rem(font-size, 54px);
    }
}