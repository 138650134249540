.gray-callout {
    background:$color-gray;
    border-radius: 20px;
    padding:20px;
    margin:0 0 20px;
}
.gray-callout__icon {
    width:60px;
    height:60px;

    &.gray-callout__icon--citrus {
        fill:$color-orange;
    }
    &.gray-callout__icon--vibrant-blue {
        fill:$color-primary;
    }
    &.gray-callout__icon--seaweed {
        fill:$color-green;
    }
    &.gray-callout__icon--moss {
        fill:$color-teal;
    }
    &.gray-callout__icon--gold {
        fill:$color-gold;
    }
    &.gray-callout__icon--lupine {
        fill:$color-purple;
    }
    &.gray-callout__icon--waves {
        fill:$color-light-blue;
    }
    &.gray-callout__icon--deep-moss {
        fill:$color-green3;
    }
    &.gray-callout__icon--tomato {
        fill:$color-red;
    }
    &.gray-callout__icon--grass {
        fill:$color-green2;
    }
    &.gray-callout__icon--spring {
        fill:$color-green4;
    }
    &.gray-callout__icon--ice {
        fill:$color-ice;
    }
    &.gray-callout__icon--lilac {
        fill:$color-lilac;
    }
    &.gray-callout__icon--lobster {
        fill:$color-lobster;
    }
    &.gray-callout__icon--stone {
        fill:$color-stone;
    }
    &.gray-callout__icon--granite {
        fill:$color-granite;
    }
}
.gray-callout__content {
    margin: 0 0 20px;
}
.gray-callout__heading {
    font-weight:400;
    @include x-rem(font-size, 32px);
    margin: 0 0 20px;
    line-height:1.2;
}
.gray-callout__description {
    font-weight:400;
    @include x-rem(font-size, 16px);
    line-height:1.5;

    *:last-child {
        margin:0;
    }
}

.gray-callout__button {
    display:block;
}

.location-search {

}
.location-search__label {
    @include x-rem(font-size, 14px);
    font-weight: 700;
    margin:0 0 10px;
    display:block;
}
.location-search__inner {

}
.location-search__select {
    width: 100%;
    margin:0 0 20px;
    border:1px solid #808080;
    border-radius: 6px;
    background:#fff;
    height:50px;
}
.location-search__button {
    width:100%;
}

@media (min-width: $bp-mw) {
    .gray-callout {
        padding:50px;
        margin:0;
        height:100%;
    }
    .gray-callout__icon {
        width:80px;
        height:80px;
    }
    .gray-callout__heading {
        @include x-rem(font-size, 36px);
    }
    .gray-callout__description {
        @include x-rem(font-size, 18px);
    }
    .gray-callout__button {
        display:inline-block;
    }

    .gray-callout--full,
    .gray-callout--full_column {

        .gray-callout__inner {
            max-width:1124px;
            margin:0 auto;
            display:flex;
            align-items: center;
        }
        .gray-callout__icon {
            flex:1 0 100px;
            margin:0 40px 0 0;
            width:100px;
            height:100px;
        }
        .gray-callout__content {
            padding:0 8% 0 0;
            margin:0;
        }
        .gray-callout__button {
            white-space: nowrap;
            margin:0 0 0 auto;
        }
    }

    .location-search {
        
    }
    .location-search__label {
    
    }
    .location-search__inner {
        display:flex;
        align-items: flex-start;
    }
    .location-search__select {
        width: 100%;
        margin:0;
    }
    .location-search__button {
        margin:0 0 0 20px;
        flex:0;
    }
    .col-md-3, .col-md-4 {
        .location-search__inner {
            display:block;
        }
        .location-search__button {
            margin:20px 0 0 0;
            width: auto;
        }
    }
}