
.rates-table {
    width:100%;
    border-collapse: collapse;
    margin:20px 0;
    border-top:1px solid #cccccc;
    tr {
        border-bottom:1px solid #cccccc;
        padding:20px;
        display:block;

        &:first-child {
            display:none;
        }
    }
    td {
        color:#000;
        padding:0 0 20px;
        text-align: left;
        display:block;

        &:last-child {
            padding:0;
        }
    }
    tr:nth-of-type(odd) {
        background:#f2f2f2;
    }
    .rates-table__mobile-heading {
        font-weight:400;
        @include x-rem(font-size, 16px);
        margin:0 0 10px;
    }
}

.print-container {
    .rates-table {
        border:0;

        tr {
            padding:20px;
            display:table-row;
    
            &:first-child {
                display:table-row;
            }
        }
        th {
            background:$color-green;
            color:#000;
            font-weight:400;
            @include x-rem(font-size, 18px);
            padding:20px;
            text-align: left;
        }
        td {
            display:table-cell;
            padding:20px;
        }
        .rates-table__mobile-heading {
            display:none;
        }
    }
}

@media (min-width: $bp-mw) {

    .rates-table {
        border:0;

        tr {
            padding:20px;
            display:table-row;
    
            &:first-child {
                display:table-row;
            }
        }
        th {
            background:$color-green;
            color:#000;
            font-weight:400;
            @include x-rem(font-size, 18px);
            padding:20px;
            text-align: left;
        }
        td {
            display:table-cell;
            padding:20px;

            &:last-child {
                padding:20px;
            }
        }
        .rates-table__mobile-heading {
            display:none;
        }
    }
}

.rate-table-heading {
    font-weight: 400;
    @include x-rem(font-size, 18px);
}
.rate-table-disclosure {

}