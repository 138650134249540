.testimonials {

}
.testimonials__content {
    max-width: $container-size-small;
    text-align: center;
    margin: 0 auto 30px;

    .heading {
        margin: 0 0 15px;
    }
    .section-description {
        font-size: pxtorem(18px);
    }
}

.testimonials-slider {
    margin:0 -15px;

    .slick-track {
        display:flex;
        padding:0 0 20px;
    }
    .slick-slide {
        height:auto;

        > div {
            height:100%;
        }
    }
    .slick-dots {
        list-style: none;
        padding:0;
        margin:20px 0 0;
        display:flex;
        justify-content: center;

        li {
            padding:0 15px;

            button {
                width:12px;
                height:12px;
                background:transparent;
                border:1px solid #000;
                border-radius: 50%;
                padding:0;
                text-indent: -9999em;
                cursor: pointer;
            }

            &.slick-active {
                button {
                    background:#000000;
                }
            }
        }
    }
}
.testimonials-slide {
    height:100%;

    &.slick-slide {
        float:none;
        height:auto;
    }
}
.testimonials-slide__inner {
    margin:0 15px;
    background: #FBFFEE;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding:40px 30px;
    height:100%;
}
.testimonials-slide__content {
    font-weight:400;
    line-height:1.5;
    margin:0 0 20px;
    @include x-rem(font-size, 14px);
}
.testimonials-slide__by {
    font-weight:700;
    @include x-rem(font-size, 14px);
}

.testimonials-slider-arrows {
    display:none;
    padding:60px 0 0;
}
.testimonials-slider-arrow {
    width:20px;
    height:40px;
    border:0;
    background:none;
    padding:0;
    cursor: pointer;
    color:$color-primary-alt;

    .testimonials-slider-arrow__icon {
        fill:currentColor;
        width:20px;
        height:40px;
    }

    &.testimonials-slider-arrow--prev {
        margin:0 60px 0 0;
    }
}

@media (min-width: 560px) {
    .testimonials-slider-arrows {
        display:flex;
    }
}
@media (min-width: $bp-mw) {
    .testimonials-slide__inner {
        padding:40px;
    }
    .testimonials-slide__content {
        @include x-rem(font-size, 16px);
    }
}