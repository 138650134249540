
.side-by-side {
    .button {
        display: block;
    }
}
.side-by-side__img-container {
    margin: 0 0 25px;
    position: relative;

    img {
        position: relative;
    }
}

.side-by-side--component {
    .side-by-side__img-container {
        padding-top:25px;
    }
}

.side-by-side--mask {
    .side-by-side__img-container {
        padding:8px 25px 25px 25px;

        &:before {
            content:'';
            position: absolute;
            width:100%;
            height:100%;
            top:0;
            left:0;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            transform: scale(.97);
        }
    }

    &.side-by-side--mask-citrus {
        .side-by-side__img-container {
            &:before {
                background-image:url(../images/circle-citrus.png);
            }
        }
    }
    &.side-by-side--mask-vibrant-blue {
        .side-by-side__img-container {
            &:before {
                background-image:url(../images/circle-vibrant-blue.png);
            }
        }
    }
    &.side-by-side--mask-seaweed {
        .side-by-side__img-container {
            &:before {
                background-image:url(../images/circle-seaweed.png);
            }
        }
    }
    &.side-by-side--mask-moss {
        .side-by-side__img-container {
            &:before {
                background-image:url(../images/circle-moss.png);
            }
        }
    }
    &.side-by-side--mask-gold {
        .side-by-side__img-container {
            &:before {
                background-image:url(../images/circle-buttercup.png);
            }
        }
    }
    &.side-by-side--mask-lupine {
        .side-by-side__img-container {
            &:before {
                background-image:url(../images/circle-lupine.png);
            }
        }
    }
    &.side-by-side--mask-waves {
        .side-by-side__img-container {
            &:before {
                background-image:url(../images/circle-waves.png);
            }
        }
    }
    &.side-by-side--mask-deep-moss {
        .side-by-side__img-container {
            &:before {
                background-image:url(../images/circle-deep-moss.png);
            }
        }
    }
    &.side-by-side--mask-tomato {
        .side-by-side__img-container {
            &:before {
                background-image:url(../images/circle-tomato.png);
            }
        }
    }
    &.side-by-side--mask-grass {
        .side-by-side__img-container {
            &:before {
                background-image:url(../images/circle-grass.png);
            }
        }
    }
    &.side-by-side--mask-spring {
        .side-by-side__img-container {
            &:before {
                background-image:url(../images/circle-spring.png);
            }
        }
    }
    &.side-by-side--mask-ice {
        .side-by-side__img-container {
            &:before {
                background-image:url(../images/circle-ice.png);
            }
        }
    }
    &.side-by-side--mask-lilac {
        .side-by-side__img-container {
            &:before {
                background-image:url(../images/circle-lilac.png);
            }
        }
    }
    &.side-by-side--mask-lobster {
        .side-by-side__img-container {
            &:before {
                background-image:url(../images/circle-lobster.png);
            }
        }
    }
    &.side-by-side--mask-stone {
        .side-by-side__img-container {
            &:before {
                background-image:url(../images/circle-stone.png);
            }
        }
    }
    &.side-by-side--mask-granite {
        .side-by-side__img-container {
            &:before {
                background-image:url(../images/circle-granite.png);
            }
        }
    }
}

.side-by-side__heading {
    margin:0 0 15px;
    line-height:1.2;
    @include x-rem(font-size, 32px);
}
.side-by-side__sub-heading {
    margin:0 0 15px;
    @include x-rem(font-size, 22px);
    font-weight:700;
}
.side-by-side__description {
    margin:0 0 15px;
    font-weight:400;
    line-height:1.5;

    &:last-child {
        margin:0;
    }

    ul {
        padding:0 0 0 20px;
    }
}

.side-by-side__links {

    
}
.side-by-side__link {
    margin:0 0 15px;
    
    .button {
        display:block;  
    }

    &:last-child {
        margin:0;
    }
}


.side-by-side--video {

    &.side-by-side--vertical {
        max-width: 1040px;
        margin:0 auto;
    }

    .side-by-side__img-container {
        margin:40px 0 0;
    }

    &.side-by-side--mask {
        .side-by-side__img-container {
            padding:0;
    
            &:before {
                content:'';
                position: absolute;
                width:100%;
                height:calc(100% + 20px);
                top:-10px;
                left:auto;
                right:-10px;
                background-size: auto 100%;
                background-position: 100%;
            }
        }
    }
}


.side-by-side__video-image {
    position: relative;
    background-size: cover;
    background-position: 50% 50%;
    border-radius:10px;
    display:block;
    cursor: pointer;

    &:before {
        content:'';
        position: absolute;
        top:50%;
        left:50%;
        width: 50px;
        height:50px;
        border-radius: 50%;
        border:5px solid #ffffff;
        background:rgba(0,0,0,.2);
        transform: translateX(-50%) translateY(-50%);
    }

    span {
        overflow: hidden;
        position: absolute;
        left:50%;
        top:50%;
        border-radius: 20%;
        transform: translateY(-50%) translateX(-50%) rotate(120deg) skewY(30deg) scaleX(.866);
        pointer-events: none;
        width:1.5em;
        height:1.5em;
        margin:0 0 0 -7px;

        &:before, &:after {
            position: absolute;
            background: #ffffff;
            pointer-events: auto;
            content: '';
            width:1.5em;
            height:1.5em;
        }
        &:before {
            border-radius: 20% 20% 20% 53%;
            transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) 
                    skewX(30deg) scaleY(.866) translateX(-24%);
        }
        &:after {
            border-radius: 20% 20% 53% 20%;
            transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) 
                    skewX(-30deg) scaleY(.866) translateX(24%);
        }
    }

    &::after {
        content: "";
        display: block;
        padding-top: percentage(188/335);
    }

    &:hover,
    &:focus {
        transform: scale(1.05);
    }
}

@media (min-width: $bp-m) {
    .side-by-side {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
    }

    .side-by-side--flipped {
        .side-by-side__img-container {
            order: 2;
            margin: 0 0 0 10%;
        }
    }

    .side-by-side__box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
        width: 40%;
    }

    .side-by-side__content {
        width: 100%;
    }

    .side-by-side__heading {
        margin:0 0 15px;
        line-height:1.2;
        @include x-rem(font-size, 48px);
    }
    .side-by-side__sub-heading {
        margin:0 0 15px;
        @include x-rem(font-size, 24px);
    }
    .side-by-side__description {
        @include x-rem(font-size, 18px);
        margin:0 0 30px;
    }

    .side-by-side__img-container {
        flex: 1 1 auto;
        margin: 0 10% 0 0;
        min-width:0;
        min-height:0;
    }

    .side-by-side--component {
        .side-by-side__box {
            width:45%;
        }
        .side-by-side__img-container {
            margin:0 0 0 6%;
        }
    }

    .side-by-side__link {
        margin:0 0 30px;
        
        .button {
            display:inline-block;  
        }
    
        &:last-child {
            margin:0;
        }
    }

    .side-by-side--video {
        display:block;

        .side-by-side__box {
            width:auto;
        }
        .side-by-side__img-container {
            
        }
    }
}
@media (min-width: $bp-l) {

    .side-by-side--video {
        display:flex;

        .side-by-side__box {
            width:40%;
        }
        
        .side-by-side__img-container {
            margin:0 0 0 10%;
        }

        &.side-by-side--mask {
            .side-by-side__img-container {
                padding:0;
        
                &:before {
                    content:'';
                    position: absolute;
                    width:100%;
                    height:calc(100% + 80px);
                    top:-40px;
                    left:auto;
                    right:-60px;
                    background-size: auto 100%;
                    background-position: 100%;
                }
            }
        }

        &.side-by-side--vertical,
        &.side-by-side--Bottom {
            display:block;

            .side-by-side__box {
                width:auto;
                max-width: 880px;
                margin:0 auto 50px;
                text-align: center;
            }
            .side-by-side__img-container {
                margin:0;
            }
        }
    }
}