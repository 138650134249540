/* ================================================================
   Alerts
   ================================================================ */

.site-alert-container {

}
.header-alert {
  display: flex;
  justify-content: center;
  padding: 20px 15px;
  background-color: $color-orange;
  color:#000;
  @include x-rem(font-size, 14px);

  a {
    color:#000;
  }

  &--citrus {
    background-color: $color-orange;
    color: #000;

    a {
      color:#000;
    }
  }
  &--vibrant-blue {
    background-color: $color-primary;
    color:#ffffff;

    a {
      color: #ffffff; 
    }
  }
  &--seaweed {
    background-color: $color-green;
    color:#000;

    a {
      color:#000;
    }
  }
  &--moss {
    background-color: $color-teal;
    color:#ffffff;

    a {
      color:#ffffff;
    }
  }
  &--gold {
    background-color: $color-gold;
    color:#000;

    a {
      color:#000;
    }
  }
  &--lupine {
    background-color: $color-purple;
    color:#fff;

    a {
      color:#fff;
    }
  }
  &--waves {
    background-color: $color-light-blue;
    color:#000;

    a {
      color:#000;
    }
  }
  &--deep-moss {
    background-color: $color-green3;
    color:#ffffff;

    a {
      color:#ffffff;
    }
  }
  &--tomato {
    background-color: $color-red;
    color:#ffffff;

    a {
      color:#ffffff;
    }
  }
  &--grass {
    background-color: $color-green2;
    color:#000;

    a {
      color:#000;
    }
  }
  &--spring {
    background-color: $color-green4;
    color:#000;

    a {
      color:#000;
    }
  }
  &--ice {
    background-color: $color-ice;
    color:#000;

    a {
      color:#000;
    }
  }
  &--lilac {
    background-color: $color-lilac;
    color:#000;

    a {
      color:#000;
    }
  }
  &--lobster {
    background-color: $color-lobster;
    color:#000;

    a {
      color:#000;
    }
  }
  &--stone {
    background-color: $color-stone;
    color:#fff;

    a {
      color:#fff;
    }
  }
  &--granite {
    background-color: $color-granite;
    color:#000;

    a {
      color:#000;
    }
  }
}

.header-alert__inner {
  padding: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  max-width: $container-size;
  position: relative;
  padding-right:40px;
}

.header-alert__icon {
  width:40px;
  margin:0 15px 0 0;
}

.header-alert__link {
  font-weight:700;
  margin:15px 0 0;
  display:block;
}

.header-alert__close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #000000;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background:transparent;
  position: absolute;
  right:0;
  cursor: pointer;

  &:hover,
  &:focus {
    background:#ffffff;
    border:2px solid #ffffff;
  }
}

.header-alert__close-button .icon {
  position: relative;
  width: 100%;
  height: 54%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &::before, &::after {
    @include pseudo;
    background-color: #000000;
      height: 100%;
    width: 1.6px;
    border-radius: 4px;
    
  }
  &::before {
      transform: rotate(45deg);
  }
  &::after {
      transform: rotate(-45deg);
  }
}

@media (min-width: $bp-mw) {
  .header-alert__text {
    display:flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-alert__link {
    white-space: nowrap;
    margin:0 0 0 15px;
  }
}
