

.interior-two-column {

}
.interior-two-column__main {
    margin:0 0 30px;
}
.interior-two-column__rail {
    
}
@media (min-width: $bp-mw) {
    .interior-two-column {
        display:flex;
        align-items: flex-start;
    }
    .interior-two-column__main {
        width:70%;
        padding:0 6% 0 0;
        margin:0;
    }
    .interior-two-column__rail {
        width:335px;
    }
    .interior-two-column--left {
        .interior-two-column__main {
            padding:0 0 0 6%;
            order:2;
        }
    }
}