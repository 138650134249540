/* ================================================================
   News - Majority of styling is done as part of the lists.scss
   ================================================================ */

.featured-news {
    padding:0 15px;
}
.featured-news__image-container {
    
}
.featured-news__image {
    background-size: cover;
    background-position: 50% 50%;
    height:454px;
    margin:0 -15px 30px;
}
.featured-news__main-heading {
    text-align: center;
    font-weight:400;
    @include x-rem(font-size, 32px);
    margin: 0 0 20px;

    &.featured-news__main-heading--desktop {
        display:none; 
    }
}
.featured-article {
    text-decoration: none;
    color:#000;
    position: relative;
    background:#ffffff;
    padding:20px 20px 90px;
    border-radius: 20px;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);
    display: block;
    margin:0 0 30px;

    .featured-news__icon {
        position: absolute;
        left:20px;
        bottom:30px;
        width:36px;
        height:36px;
        fill:$color-primary;
        background:$color-gold;
        border-radius: 50%;
    }

    &:hover,
    &:focus {
        .featured-news__heading {
            text-decoration: underline;
        }
        .featured-news__icon {
            background:$color-primary;
            fill:$color-gold;
        }
    }
}
.featured-news__date {
    font-weight:400;
    @include x-rem(font-size, 13px);
    margin: 0 0 20px;
}
.featured-news__heading {
    font-weight:400;
    @include x-rem(font-size, 22px);
    margin:0;
}
.featured-news__description {
    font-weight:400;
    @include x-rem(font-size, 16px);
    margin: 20px 0 0;
}

.featured-news__content {
    
}
.featured-news__main-heading {

}
.featured-news__list {
    list-style: none;
    padding:0;
    margin:0 0 15px;
}
.featured-news__list-item {
    border-bottom:1px solid #808080;
    padding:20px 0;

    &:last-child {
        border:0;
    }
}
.featured-news__list-link {
    color:#000;
    text-decoration: none;
    padding:0 0 50px;
    position: relative;
    display:block;

    .featured-news__icon {
        position: absolute;
        left:0;
        bottom:0px;
        width:36px;
        height:36px;
        fill:$color-primary;
        background:$color-gold;
        border-radius: 50%;
    }

    &:hover,
    &:focus {
        .featured-news__heading {
            text-decoration: underline;
        }
        .featured-news__icon {
            background:$color-primary;
            fill:$color-gold;
        }
    }
}

.featured-news__button {
    display:block;
}

@media (min-width: $bp-mw) {
    .featured-news {
        display:flex;
        padding:0;
    }
    .featured-news__image-container {
        flex: 1 1 auto;
        margin: 0 110px 0 0;
        position: relative;
    }
    .featured-news__image {
        background-size: cover;
        height:100%;
        width: 100%;
        margin:0;
        border-radius: 0 20px 20px 0;
    }
    
    .featured-article {
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        right:-90px;
        background:#ffffff;
        padding:40px 40px 95px 40px;
        width:440px;

        .featured-news__icon {
            left:40px;
            bottom:30px;
            width:36px;
            height:36px;
        }
    }
    .featured-news__content {
        flex: 0 0 auto;
        width: 54%;
        padding:30px 15px 30px 0;
    }
    .featured-news__main-heading {
        text-align: left;
        font-weight:400;
        @include x-rem(font-size, 48px);
        margin: 0;
    
        &.featured-news__main-heading--mobile {
            display:none; 
        }
        &.featured-news__main-heading--desktop {
            display:block; 
        }
    }
    .featured-news__list {
        padding-left:60px;
        max-width:800px;
    }
    .featured-news__list-item {
        padding:40px 0;
    }
    .featured-news__list-link {
        padding:0 20% 0 0;

        .featured-news__icon {
            right:0;
            left:auto;
            top:50px;
        }
    }
    .featured-news__date {
        @include x-rem(font-size, 14px);
    }
    .featured-news__heading {
        @include x-rem(font-size, 24px);
    }
    .featured-news__description {
        @include x-rem(font-size, 18px);
    }
    .featured-news__button {
        display:inline-block;
    }
}

/* news listing */

.news-listing-controls {
    display:flex;
    max-width: 144px;
    border:1px solid #ddd;
    margin:0 0 30px;
    border-radius: 20px;
    overflow: hidden;
}
.news-listing-control {
    flex:1;
    padding:10px 20px;
    border:0;
    background:none;
    color:$color-primary-alt;
    cursor: pointer;

    &:first-child {
        border-right:1px solid #ddd;
    }

    &.active {
        background:$color-primary-alt;
        color:#fff;
    }

    &:hover,
    &:focus {
        background:#eeeeee;
        color:$color-primary-alt;
    }
}
.news-listing-control__icon {
    width:26px;
    height:26px;
    fill:currentColor;
}

.news-listing {
    list-style: none;
    padding:0;
    margin:0;
}
.news-listing__item {
    margin:0 0 30px;
}
.news-listing__block {
    color:#000;
    text-decoration: none;
    padding:0 0 50px;
    position: relative;
    display:block;

    &:hover,
    &:focus {
        .news-listing__heading {
            text-decoration: underline;
        }
        .news-listing__icon {
            fill:$color-gold;
            background:$color-primary;
        }
    }
}
.news-listing__block-content {
    
}
.news-listing__image {
    position: relative;
    background-size: cover;
    background-position: 50% 50%;
    border-radius:20px;
    margin:0 0 15px;

    &:before {
        content: "";
        display: block;
        padding-top: percentage(340/440);
    }
}
.news-listing__date {
    font-weight:400;
    @include x-rem(font-size, 13px);
    margin: 0 0 15px;
}
.news-listing__heading {
    font-weight:400;
    @include x-rem(font-size, 22px);
    margin: 0;
}
.news-listing__description {
    font-weight:400;
    @include x-rem(font-size, 16px);
    margin: 10px 0 10px;
}
.news-listing__score {
    width:110px;
    height:8px;
    border-radius: 2px;
    background:#eeeeee;
    border:1px solid #aaa;
    margin:10px 0;
    position: relative;
    overflow: hidden;

    > div {
        background:$color-gold;
        height:100%;
        position: absolute;
        top:0;
        left:0;
    }
}
.news-listing__icon {
    position: absolute;
    left:0;
    bottom:0px;
    width:36px;
    height:36px;
    fill:$color-primary;
    background:$color-gold;
    border-radius: 50%;
}



@media (min-width: 680px) {
    .news-listing {
        display:flex;
        flex-wrap: wrap;
        margin:0 -25px;
    }
    .news-listing__item {
        width:50%;
        padding:0 25px;
    }
    .news-listing__date {
        font-weight:400;
        @include x-rem(font-size, 14px);
        margin: 0 0 15px;
    }
    .news-listing__heading {
        font-weight:400;
        @include x-rem(font-size, 24px);
        margin: 0;
    }
}
@media (min-width: $bp-m) {
    .news-listing__item {
        width:33.33%;
    }
}
.news-listing--alt,
.news-listing-container--list .news-listing {
    display:block;
    margin:0;

    .news-listing__item {
        border-bottom:1px solid #808080;
        padding:0 0 30px;
        width:auto;

        &:last-child {
            border:0;
            padding:0;
        }
    }
    .news-listing__image {
        display:none;
    }
}
@media (min-width: $bp-m) {
    .news-listing--alt,
    .news-listing-container--list .news-listing {
        .news-listing__block {
            padding:0 50px 0 0;

        }
        .news-listing__icon {
            right:0;
            left:auto;
            top:36px;
            bottom:auto;
        }
    }
}

/* news details */

.news-details {
    max-width:640px;
    line-height:1.5;
}

.news-details__image {
    border-radius: 20px;
    margin:0 0 40px;
}

.news-details__published {
    margin:0 0 40px;
}

.media-contact {

}
.media-contact__heading {
    font-weight:400;
    @include x-rem(font-size, 24px);
    margin: 0 0 5px;
}
.media-contact__name {
    font-weight:400;
    @include x-rem(font-size, 20px);
    margin: 0 0 15px;
}
.media-contact__contact {
    margin:0 0 8px;
}
.media-contact__contact-link {
    display:inline-block;
    position: relative;
    font-weight:700;

    svg {
        width:20px;
        height:20px;
        fill: #091D6B;
        position: absolute;
        left:0;
        top:0;
    }
}

@media (min-width: $bp-m) {
    .news-details {
        @include x-rem(font-size, 18px);
    }
}