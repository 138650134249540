.product-details {

}
.product-details__main {

    h1,
    h2 {
        margin:0 0 25px;
        @include x-rem(font-size, 32px);
        font-weight:400;
    }
    ul {
        font-weight:400;

        li {
            margin:0 0 10px;
        }
    }
}
.product-details__rail {
    padding:25px 0 0;
}

.rail-callout {
    border-radius: 20px;
    padding:30px;
    margin:0 0 30px;

    &--citrus {
        background-color: $color-orange;

    }
    &--vibrant-blue {
        background-color: $color-primary;

    }
    &--seaweed {
        background-color: $color-green;

    }
    &--moss {
        background-color: $color-teal;

    }
    &--gold {
        background-color: $color-gold;

    }
    &--lupine {
        background-color: $color-purple;

    }
    &--waves {
        background-color: $color-light-blue;

    }
    &--deep-moss {
        background-color: $color-green3;

    }
    &--tomato {
        background-color: $color-red;

    }
    &--grass {
        background-color: $color-green2;

    }
    &--spring {
        background-color: $color-green4;

    }
    &--ice {
        background-color: $color-ice;

    }
    &--lilac {
        background-color: $color-lilac;

    }
    &--lobster {
        background-color: $color-lobster;

    }
    &--stone {
        background-color: $color-stone;

    }
    &--granite {
        background-color: $color-granite;

    }

    .section-container {
        background:transparent;
    }

    .rail-callout__heading,
    h2,
    h3 {
        @include x-rem(font-size, 24px);
        font-weight:400;
        margin:0 0 15px;
    
        &:last-child {
            margin:0;
        }
    }

    p {
        font-weight: 400;
    }

    .button, .btn, .button-2, .button-ghost {
        width:100%;
        display:block;
        margin:0 0 30px;
    
        &:last-child {
            margin:0;
        }
    }
}


.rail-callout__button,
a.rail-caoout__button {
    width:100%;
    display:block;
    margin:0 0 30px;

    &:last-child {
        margin:0;
    }
}

.rail-callout__link {
    display:flex;
    align-items: center;
    margin:20px 0 0;
}
.rail-callout__link-icon {
    width:22px;
    height:22px;
    fill:$color-primary-alt;
    margin:0 10px 0 0;
}

@media (min-width: $bp-mw) {
    .product-details {
        display:flex;
        justify-content: space-between;
    }
    .product-details__main {
        width:100%;
        padding:0 12% 0 0;
        @include x-rem(font-size, 18px);

        h1,h2 {
            @include x-rem(font-size, 36px);
        }
    }
    .product-details__rail {
        width:355px;
        flex:1 0 355px;
        padding:0;
    }
}