.locations-container {
    background:#eee;
    position: relative;

    /* pin overlay */

    .gm-style .gm-style-iw-c {
        padding:0;

        button {
            right:0 !important;
            top:0 !important;
        }
    }
    .gm-style .gm-style-iw-d {
        overflow: auto !important;
    }
    .location-overlay {
        padding:20px;
    }
    .location-overlay__header {
        font-weight:700;
        @include x-rem(font-size, 24px);
        display:block;
        margin:0 0 5px;
    }
    .location-overlay__address {
        @include x-rem(font-size, 18px);
        font-weight:400;
        margin:0 0 10px;
    }
    .location-overlay__link {
        @include x-rem(font-size, 18px);
        font-weight:400;
    }
}
.locations-map-container {
    height:375px;
}
.locations-rail {
    background:#ffffff;
    height:640px;
    width:100%;
    display:flex;
    flex-flow: column;
}

.locations-rail__top {
    padding:20px;
}
.locations-rail__label {
    display:block;
    font-weight:700;
    @include x-rem(font-size, 14px);
    margin:0 0 10px;
}
.locations-rail__select {
    width:100%;
    margin:0 0 25px;
    border:1px solid #808080;
    border-radius: 6px;
    height:50px;
}
.locations-rail__helpers {

}
.locations-rail__helper {
    font-weight:700;
    text-decoration: none;

    &:after {
        content:'|';
        margin:0 5px 0 10px;
        color:#ccc;
    }

    &:hover,
    &:focus {
        text-decoration: underline;
    }

    &:last-child {
        &:after {
            display:none;
        }
    }
}

.locations-rail__list-container {
    overflow:hidden;
    overflow-y: scroll;
    height:100%;
}

.locations-list {
    list-style: none;
    padding:0 20px 20px 20px;
    margin:0;
}

.locations-rail__num-results {
    padding:0 20px 20px;
    font-weight:bold;
}

.locations-list__item {
    border-top:1px solid #CCCCCC;
    padding:15px 0;
}

.locations-list__heading {
    @include x-rem(font-size, 22px);
    font-weight: 400;
    margin: 0 0 10px;
    line-height: 1.3;
}
.locations-list__heading-link {
    display:block;
    position: relative;
    color:$color-base;
    text-decoration: none;

    &:before {
        content:'';
        position: absolute;
        right:0px;
        width:8px;
        height:2px;
        background:$color-primary;
        transform: rotate(45deg);
        top:13px;
    }
    &:after {
        content:'';
        position: absolute;
        right:0px;
        width:8px;
        height:2px;
        background:$color-primary;
        transform: rotate(-45deg);
        top:18px;
    }

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
.locations-list__info-blocks {
    
}
.locations-list__info-col {
    flex:1;
}
.locations-list__info-block {
    position: relative;
    padding:0 0 0 25px;
    margin:0 0 15px;
}
.locations-list__info-icon {
    width:16px;
    height:16px;
    fill: #091D6B;
    position: absolute;
    left:0;
    top:0;
}
.locations-list__info-title {
    font-weight:600;
    @include x-rem(font-size, 14px);
    margin:0;
}
.locations-list__info-content {
    font-weight:400;
    @include x-rem(font-size, 14px);
    line-height: 1.5;
    margin:0 0 10px;

    a {
        font-weight:700;
        @include x-rem(font-size, 16px);
        text-decoration: none;

        svg {
            width:14px;
            height:14px;
            fill:currentColor;
            margin:0 0 0 3px;
        }

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
    p {
        @include x-rem(font-size, 14px);
        &:last-child {
            margin:0;
        }
    }
}

@media (min-width: $bp-mw) {
    .locations-container {
        height:800px;
    }
    .locations-map-container {
        height:100%;
    }
    
    .locations-rail {
        position: absolute;
        top:30px;
        left:15px;
        height:calc(100% - 60px);
        background:#ffffff;
        width:460px;
        border-radius: 20px;
        display:flex;
        flex-flow: column;
    }
    .locations-list__info-blocks {
        display:flex;
    }
}

@media (min-width: 1420px) {
    .locations-rail {
        left:160px;
    }
}

/* Locations Details */

.location-details {

    h2 {
        font-weight:400;
        @include x-rem(font-size, 32px);
        margin:0 0 10px;
    }
}

.location-details__block {
    margin:0 0 30px;
}

.location-detail__link {
    display:inline-flex;
    align-items: center;
    text-decoration: none;
    margin:15px 0 0;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
.location-detail__link-icon {
    width:30px;
    height:30px;
    fill:currentColor;
    margin:0 10px 0 0;
}

.amenities {
    background:$color-gray;
    border-radius: 20px;
    padding:20px;
    margin:0 0 20px;
}
.amenity {
    display:flex;
    align-items: center;
    text-decoration: none;
    margin:15px 0 0;
    @include x-rem(font-size, 18px);
}
.amenity__icon {
    width:30px;
    height:30px;
    margin:0 10px 0 0;
}
.amenitiy-footnote {
    margin:0 0 5px;
    @include x-rem(font-size, 14px);
}

.location-detail-map {
    width:100%;
    height:400px;
}
@media (min-width: 600px) {
    .location-details {
        display:flex;
        justify-content: space-between;
    }
    .location-details__left {
        width:60%;
    }
    .location-details__right {
        width:40%;
    }
}
@media (min-width: $bp-mw) {
    
    .location-details__left {
        width:60%;
    }
    .location-details__right {
        width:30%;
    }
    .location-details__blocks {
        display:flex;
        margin:0 -15px;
        @include x-rem(font-size, 18px);
    }
    .location-details__block {
        padding:0 15px;
        width:33.33%;
    }
    .amenities {
        padding:40px;
    }
}