.site-header {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index:998;

    .sticky {
        position: fixed;
        top:0;
        left:0;
        right:0;
        z-index:999;
    }
}

.site-header__top {
    background:$color-gray;
    display:none;
}

.utility-navigation {
    list-style: none;
    padding:0;
    margin:0;
    display:flex;
    justify-content: flex-end;
}
.utility-navigation__item {

    &.utility-navigation__item--citrus {
        max-width:140px;

        .utility-navigation__link {
            background:$color-orange;
            color:#ffffff;
            font-weight:700;
            justify-content: center;

            &:hover,
            &:focus {
                background:$color-primary;
                color:#ffffff;
            }
        }
    }
    &.utility-navigation__item--vibrant-blue {
        max-width:140px;

        .utility-navigation__link {
            background:$color-primary;
            color:#ffffff;
            font-weight:700;
            justify-content: center;

            &:hover,
            &:focus {
                background:$color-light-blue;
                color:#000;
            }
        }
    }
    &.utility-navigation__item--seaweed {
        max-width:140px;

        .utility-navigation__link {
            background:$color-green;
            color:#000000;
            font-weight:700;
            justify-content: center;

            &:hover,
            &:focus {
                background:$color-primary;
                color:#ffffff;
            }
        }
    }
    &.utility-navigation__item--moss {
        max-width:140px;

        .utility-navigation__link {
            background:$color-teal;
            color:#ffffff;
            font-weight:700;
            justify-content: center;

            &:hover,
            &:focus {
                background:$color-primary;
                color:#ffffff;
            }
        }
    }
    &.utility-navigation__item--gold {
        max-width:142px;

        .utility-navigation__link {
            background:$color-gold;
            color:$color-primary;
            font-weight:700;
            justify-content: center;

            &:hover,
            &:focus {
                background:$color-primary;
                color:#ffffff;
            }
        }
    }
    &.utility-navigation__item--lupine {
        max-width:140px;

        .utility-navigation__link {
            background:$color-purple;
            color:#ffffff;
            font-weight:700;
            justify-content: center;

            &:hover,
            &:focus {
                background:$color-primary;
                color:#ffffff;
            }
        }
    }
    &.utility-navigation__item--waves {
        max-width:140px;

        .utility-navigation__link {
            background:$color-light-blue;
            color:#000000;
            font-weight:700;
            justify-content: center;

            &:hover,
            &:focus {
                background:$color-primary;
                color:#ffffff;
            }
        }
    }
    &.utility-navigation__item--deep-moss {
        max-width:140px;

        .utility-navigation__link {
            background:$color-green3;
            color:#ffffff;
            font-weight:700;
            justify-content: center;

            &:hover,
            &:focus {
                background:$color-primary;
                color:#ffffff;
            }
        }
    }
    &.utility-navigation__item--tomato {
        max-width:140px;

        .utility-navigation__link {
            background:$color-red;
            color:#ffffff;
            font-weight:700;
            justify-content: center;

            &:hover,
            &:focus {
                background:$color-primary;
                color:#ffffff;
            }
        }
    }
    &.utility-navigation__item--grass {
        max-width:140px;

        .utility-navigation__link {
            background:$color-green2;
            color:#ffffff;
            font-weight:700;
            justify-content: center;

            &:hover,
            &:focus {
                background:$color-primary;
                color:#ffffff;
            }
        }
    }
    &.utility-navigation__item--spring {
        max-width:140px;

        .utility-navigation__link {
            background:$color-green4;
            color:#000000;
            font-weight:700;
            justify-content: center;

            &:hover,
            &:focus {
                background:$color-primary;
                color:#ffffff;
            }
        }
    }
    &.utility-navigation__item--ice {
        max-width:140px;

        .utility-navigation__link {
            background:$color-ice;
            color:#000000;
            font-weight:700;
            justify-content: center;

            &:hover,
            &:focus {
                background:$color-primary;
                color:#ffffff;
            }
        }
    }
    &.utility-navigation__item--lilac {
        max-width:140px;

        .utility-navigation__link {
            background:$color-lilac;
            color:#000000;
            font-weight:700;
            justify-content: center;

            &:hover,
            &:focus {
                background:$color-primary;
                color:#ffffff;
            }
        }
    }
    &.utility-navigation__item--lobster {
        max-width:140px;

        .utility-navigation__link {
            background:$color-lobster;
            color:#000000;
            font-weight:700;
            justify-content: center;

            &:hover,
            &:focus {
                background:$color-primary;
                color:#ffffff;
            }
        }
    }
    &.utility-navigation__item--stone {
        max-width:140px;

        .utility-navigation__link {
            background:$color-stone;
            color:#ffffff;
            font-weight:700;
            justify-content: center;

            &:hover,
            &:focus {
                background:$color-primary;
                color:#ffffff;
            }
        }
    }
    &.utility-navigation__item--granite {
        max-width:140px;

        .utility-navigation__link {
            background:$color-granite;
            color:#000000;
            font-weight:700;
            justify-content: center;

            &:hover,
            &:focus {
                background:$color-primary;
                color:#ffffff;
            }
        }
    }
}
.utility-navigation__link {
    font-weight:400;
    @include x-rem(font-size, 14px);
    padding:16px 30px;
    text-decoration: none;
    display:block;
    color:#000000;
    height:50px;
    display:flex;
    align-items: center;
    text-align: center;
    line-height:1;

    &:hover,
    &:focus {
        background:$color-primary;
        color:#ffffff;
    }
}


.site-header__bottom {
    background: #ffffff;
    padding:20px 0;
    position: relative;
}

.site-header__bottom-inner {
    display:flex;
    justify-content: flex-end;
    align-items: center;
}

.header-logo {
    font-size: 0;
    margin:0 auto 0 0;
    max-width:130px;

    a {
        display: inline-block;
    }
}


.header-search-trigger {
    @include resetBtn;
    display: block;
    width: 24px;
    height: 24px;
    font-size: 0;
    color: #000;
    flex: 0 0 auto;
    margin:0 20px 0 20px;

    &:hover,
    &:focus {
        color: $color-secondary;
    }

    svg {
        width: 100%;
        height: 100%;
        fill: currentColor;
    }
    
    &.header-search-trigger--desktop {
        display:none;
    }
}

.header-search {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 100;
    padding:20px 15px;
    background:#F2F2F2;
    display:none;
}
.header-search__inner {
    max-width:1510px;
    margin: 0 auto;
    position: relative;
    display:flex;
}

.header-search__input {
    display: block;
    width: 100%;
    height: 50px;
    background: #ffffff;
    padding: 10px $grid-gutter-em;
    border:1px solid #808080;
    border-radius: 6px;
    margin:0 102px 0 0;

    @include placeholder {
      color: #000;
      opacity: 1;
    }
}

.header-search__submit {
    position: absolute;
    top:0;
    right: 0;
    padding:15px 20px;
    @include x-rem(font-size, 15px);
}


.header-login {
    position: relative;
}
.header-login-button {
    background:$color-primary;
    color:#ffffff;
    font-weight:700;
    display: inline-block;
    position: relative;
    display:flex;
    align-items: center;
    padding:16px 36px 16px 16px;
    border-radius:70px;
    border:0;
    cursor:pointer;

    svg {
        fill:currentColor;
        width:18px;
        height:18px;
        margin:0 7px 0 0;
    }

    &:before {
        content:'';
        position: absolute;
        right:22px;
        width:8px;
        height:1px;
        background:#fff;
        transform: rotate(45deg);
        top:50%;
    }
    &:after {
        content:'';
        position: absolute;
        right:16px;
        width:8px;
        height:1px;
        background:#fff;
        transform: rotate(-45deg);
        top:50%;
    }

    &.active {
        border-radius: 70px 70px 0 70px;
    }

    &:hover,
    &:focus {
        background:$color-secondary;
    }
}
.header-login__flyout {
    position: absolute;
    right:0;
    top:100%;
    width:336px;
    background:$color-primary;
    border-radius: 25px 0 25px 25px;
    padding:40px 20px;
    z-index:30;
    display:none;
}
.header-login__label {
    display:block;
    color:#ffffff;
    font-weight:700;
    @include x-rem(font-size, 14px);
    margin:0 0 10px;
}
.header-login__input {
    border:1px solid #808080;
    height:50px;
    background:#ffffff;
    border-radius: 6px;
    margin:0 0 15px;
    width:100%;
}
.header-login__forgot {
    color:#ffffff;
    font-weight:400;
    @include x-rem(font-size, 14px);
    text-decoration: underline;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}
.header-login__submit {
    background:$color-primary;
    border:2px solid #ffffff;
    color:#ffffff;
    width:100%;
    display:block;
    margin:25px 0;
    border-radius: 26px;
    height:50px;
    font-weight:700;
    font-family: $font-primary;
    cursor: pointer;

    &:hover,
    &:focus {
        background:#ffffff;
        color:$color-primary;
    }
}
.header-login__help {
    margin: 0 -5px;
}
.header-login__help + .header-login__help {
    margin:15px -5px 0;
}
.header-login__help-heading {
    text-align: center;
    font-weight:700;
    color:#ffffff;
    margin:0 0 15px;
    @include x-rem(font-size, 14px);
}
.header-login__help-links {
    list-style: none;
    margin:0;
    padding:0;
    display:flex;
    justify-content: center;
}
.header-login__help-links-item {
    border-right:1px solid #ffffff;
    flex:1;
    text-align: center;

    &:first-child {
        padding-left:0;
    }

    &:last-child {
        padding-right:0;
        border:0;
    }
}
.header-login__help-link {
    font-weight:700;
    color:#ffffff;
    text-decoration: none;
    @include x-rem(font-size, 13.5px);

    svg {
        fill:currentColor;
        width:14px;
        height: 10px;
        margin:0 0 0 2px;
    }

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
.header-login__bottom {
    border-top:1px solid #ffffff;
    padding:20px 0 0 0;
    margin:25px 0 0 0;
    text-align: center;
}
.header-login__bottom-link {
    color:#ffffff;
    font-weight:400;
    @include x-rem(font-size, 14px);
    text-decoration: underline;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.site-header__fdic {
    margin:10px 0 -10px 0;
    img {
        max-width:400px;
    }
}


.main-navigation-container {
    position: absolute;
    display:none;
    left:0;
    top:100%;
    width: 100%;
    z-index:100;
    background: #ffffff;
    z-index: 90;
    height:calc(100vh - 128px);
    overflow: auto;

    .utility-navigation {
        display:flex;
        flex-wrap: wrap;
        padding:20px;

        .utility-navigation__item {
            width:100%;
        }
        .utility-navigation__link {
            padding:12px 10px;
            height:auto;
        }
        [class*="utility-navigation__item--"] {
            order:-1;
            width:50%;
            max-width:none;
            margin:0 0 10px;

            .utility-navigation__link {
                height:50px;
            }
        }
    }
}
/* mobile nav site overlay */
.js-data-toggled-header-nav {
    .site-container {
        position: relative;

        &:before {
            content:'';
            position:fixed;
            left:0;
            top:0;
            width:100%;
            height:100%;
            background:#fff;
            z-index: 80;
        }
    }
}

@media (min-width: $bp-mw) {
    .site-header {
        .sticky {
            position: fixed;
            top:0;
            left:0;
            right:0;
            z-index:999;
            
            .header-logo {
                max-width: 120px;
            }
        }
    }
    * {
        scroll-margin-top: 200px;
    }
    .site-header__top {
        display:block;
    }
    .header-logo {
        width:100%;
        max-width:210px;
        padding:0 20px 0 0;
        margin:0 auto 0 0;
        transition: $trans;
    }

    .header-search-trigger {
        &.header-search-trigger--mobile {
            display:none;
        }
        &.header-search-trigger--desktop {
            display:block;
        }
    }
    .header-search__input {
        margin:0 136px 0 0;
    }
    .header-search__submit {
        padding:15px 30px;
        @include x-rem(font-size, 16px);
    }
    .header-login {
        order:3;
    }
    .site-header__fdic {
        margin:10px 0 0 0;
    }
    .main-navigation-container {
        position: static;
        display:block !important;
        width:auto;
        background:none;
        height:auto;
        overflow: inherit;

        .utility-navigation {
            display:none;
        }
    }
    /* mobile nav site overlay */
    .js-data-toggled-header-nav {
        .site-container {
            &:before {
                display:none;
            }
        }
    }
}
@media (min-width: $bp-l) {
    .header-login-button {
        padding:16px 46px 16px 24px;

        &:before {
            right:26px;
        }
        &:after {
            right:20px;
        }
    }
}